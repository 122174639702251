import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useState } from 'react';

const AdditionProcessMaster = ({ open, onClose, onAdd }) => {
  const [selectedProcess, setSelectedProcess] = useState('');

  const processes = [
    '基板準備 (L2)',
    '1層合成 (L2)',
    'p+合成 (L2)',
    // 他のプロセスを追加
  ];

  const handleAdd = () => {
    if (selectedProcess) {
      onAdd(selectedProcess);
      setSelectedProcess('');
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>プロセス追加</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="process-select-label">プロセス</InputLabel>
          <Select
            labelId="process-select-label"
            value={selectedProcess}
            onChange={(e) => setSelectedProcess(e.target.value)}
            label="プロセス"
          >
            {processes.map((process) => (
              <MenuItem key={process} value={process}>
                {process}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleAdd} variant="contained" color="primary">
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdditionProcessMaster;