// @ts-check
export const renderTableHeaders = (activeTab) => {
  switch (activeTab) {
    case 0: // ステータス
      return ['No.', '基板ID', '設計', 'ステータス', '更新者', '所在地'];
    case 1: // 用途
      return ['No.', '基板ID', 'プロジェクト', '基板用途', '設計', '管理者', '備考'];
    case 2: // 購入情報
      return ['No.', '基板ID', '厚さ(μm)', '面方位', 'カット向き', 'メーカー', '製品名', '購入予算', '登録時備考', '登録日', '更新日'];
    case 3: // 加工状況
      return ['No.', '基板ID', '精密研磨状況', '研磨依頼先', '研磨条件'];
    default:
      return [];
  }
};

export const renderTableRow = (activeTab, substrate) => {
  switch (activeTab) {
    case 0: // ステータス
      return substrate.getStatusData();
    case 1: // 用途
      return substrate.getUsageData();
    case 2: // 購入情報
      return substrate.getPurchaseData();
    case 3: // 加工状況
      return substrate.getProcessingData();
    default:
      return [];
  }
};