import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../constants/colors';

const ProcessReusableSearch = ({ label, placeholder, processes, creators, onSearch, onFilter }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProcess, setSelectedProcess] = useState('');
  const [selectedCreator, setSelectedCreator] = useState('');

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  useEffect(() => {
    onFilter({ process: selectedProcess, creator: selectedCreator });
  }, [selectedProcess, selectedCreator, onFilter]);

  return (
    <Paper sx={{ padding: 2, backgroundColor: COLORS.BACKGROUND_GRAY }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={10}>
          <TextField
            sx={{ backgroundColor: 'white' }}
            fullWidth
            label={label}
            placeholder={placeholder}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            sx={{ backgroundColor: COLORS.PRIMARY }}
            onClick={handleSearch}
          >
            検索
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>L2プロセス</InputLabel>
            <Select
              value={selectedProcess}
              label="L2プロセス"
              onChange={(e) => setSelectedProcess(e.target.value)}
              sx={{ backgroundColor: 'white' }}
            >
              {processes.map((process) => (
                <MenuItem key={process.value} value={process.value}>
                  {process.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>作成者</InputLabel>
            <Select
              value={selectedCreator}
              label="作成者"
              onChange={(e) => setSelectedCreator(e.target.value)}
              sx={{ backgroundColor: 'white' }}
            >
              {creators.map((creator) => (
                <MenuItem key={creator.value} value={creator.value}>
                  {creator.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProcessReusableSearch;