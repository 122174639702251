import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../firebase";


export const GetTemplateFile = async () => {
    try {
        const fileRef = ref(storage, `templates/register_substrate.xlsx`)
        const url = await getDownloadURL(fileRef)
        console.log('get image URL is success!!', url)
        window.open(url, '_blank')
        return url
    } catch (error) {
        console.error('Failed to get image URL', error)
        return null
    }
}