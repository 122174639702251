export class User {
    /**
     * @param {Object} data - Firestoreから取得したユーザーデータ
     */
    constructor(data) {
        this.uid = data.uid || '';
        this.userAtCreated = data.user_at_created || '';
        this.userAtLastLogin = data.user_at_last_login || '';
        this.userBelongToId = data.user_belong_to_id || '';
        this.userBelongToLabel = data.user_belong_to_label || '';
        this.userBelongToName = data.user_belong_to_name || '';
        this.userEmail = data.user_email || '';
        this.userIcon = data.user_icon || '';
        this.userKanaFirst = data.user_kana_first || '';
        this.userKanaLast = data.user_kana_last || '';
        this.userNameFirst = data.user_name_first || '';
        this.userNameLast = data.user_name_last || '';
    }

    /**
     * フルネームを取得
     * @returns {string} フルネーム
     */
    getFullName() {
        return `${this.userNameLast} ${this.userNameFirst}`.trim();
    }

    /**
     * フルネーム（カナ）を取得
     * @returns {string} フルネーム（カナ）
     */
    getFullNameKana() {
        return `${this.userKanaLast} ${this.userKanaFirst}`.trim();
    }

    /**
     * オブジェクトとしてデータを取得
     * @returns {Object} ユーザーデータのプレーンオブジェクト
     */
    toObject() {
        return {
            uid: this.uid,
            userAtCreated: this.userAtCreated,
            userAtLastLogin: this.userAtLastLogin,
            userBelongToId: this.userBelongToId,
            userBelongToLabel: this.userBelongToLabel,
            userBelongToName: this.userBelongToName,
            userEmail: this.userEmail,
            userIcon: this.userIcon,
            userKanaFirst: this.userKanaFirst,
            userKanaLast: this.userKanaLast,
            userNameFirst: this.userNameFirst,
            userNameLast: this.userNameLast
        };
    }
}