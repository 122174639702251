import { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes, useLocation, useNavigate
} from 'react-router-dom';
import store from './redux/store';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';

import styled from '@emotion/styled';

import Header from './components/Header';

import AccountScreen from './screens/AccountScreen';
import LoginScreen from './screens/LoginScreen';
import SignUpScreen from './screens/SignUpScreen';

import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import React from 'react';
import { Loading } from './commons/Loading';
import {
  ADMINISTRATOR, GENERAL_USER,
  PATH_ACCOUNT,
  PATH_BOARD_DETAILS,
  PATH_CREATE_L2_PROCESS_MASTER,
  PATH_EDIT_L2_TYPE_MASTER,
  PATH_EDIT_MEASUREMENT_TYPE_MASTER,
  PATH_EQUIMENT_LIST,
  PATH_EQUIMENT_TYPE_MASTER_LIST,
  PATH_KIBAN_DETAIL,
  PATH_L2_PROCESS_MASTER_DETAIL,
  PATH_L2_PROCESS_MASTER_LIST,
  PATH_L2_TYPE_MASTER_LIST,
  PATH_LOGIN,
  PATH_MEASUREMENT_TYPE_MASTER_LIST,
  PATH_OTHERS,
  PATH_PROCESS_LIST,
  PATH_REGISTER_L2_TYPE_MASTER,
  PATH_REGISTER_MEASUREMENT_TYPE_MASTER,
  PATH_REGISTER_SUBSTRATE,
  PATH_REGISTER_SUBSTRATE_SUCCESS,
  PATH_ROOT,
  PATH_ROOT_OUT,
  PATH_SIGNUP,
  PATH_TOP, SUPER_USER
} from './consts/const';
import { COLOR_BACKGROUND_DARK_WHITE } from './consts/constCss';
import { auth, db } from './firebase';
import {
  selectUserIsLogin,
  setIsLogin,
  setUserInfo,
} from './redux/userDataSlice';

import { Alert, Snackbar } from "@mui/material";
import { AlertProvider, useAlert } from "./commons/AlertContext";
import BoardDetails from './screens/BoardDetails';
import EquipmentListScreen from './screens/EquipmentListScreen';
import EquipmentTypeMastarListScreen from './screens/EquipmentTypeMastarListScreen';
import KibanDetailScreen from './screens/KibanDetailScreen';
import CreateL2ProcessMastarScreen from './screens/L2ProcessMastar/CreateL2ProcessMastarScreen';
import L2ProcessMastarDetailScreen from './screens/L2ProcessMastar/L2ProcessMastarDetailScreen';
import L2ProcessMastarListScreen from './screens/L2ProcessMastar/L2ProcessMastarListScreen';
import EditL2TypeMastarScreen from './screens/L2TypeMastar/EditL2TypeMastarScreen';
import L2TypeMastarListScreen from './screens/L2TypeMastar/L2TypeMastarListScreen';
import RegisterL2TypeMastarScreen from './screens/L2TypeMastar/RegisterL2TypeMastarScreen';
import EditMeasurementTypeMastarScreen from './screens/MeasurementTypeMastar/EditMeasurementTypeMastarScreen';
import MeasurementTypeMastarListScreen from './screens/MeasurementTypeMastar/MeasurementTypeMastarListScreen';
import RegisterMeasurementTypeMastarScreen from './screens/MeasurementTypeMastar/RegisterMeasurementTypeMastarScreen';
import ProcessListScreen from './screens/ProcessList/ProcessListScreen';
import RegisterSubstrateScreen from './screens/RegisterSubstrate/RegisterSubstrateScreen';
import RegisterSubstrateSuccessScreen from './screens/RegisterSubstrate/RegisterSubstrateSuccessScreen';
import TopScreen from './screens/TopScreen';
import { getUserPermission } from './utils/getUserPermission';
import { updateFirebaseUserAtLastLogin } from './utils/updateFirebaseUserAtLastLogin';

// アラート表示
const AlertSnackbar = () => {
  const { alertInfo, showAlert } = useAlert();

  return (
    <Snackbar
      open={alertInfo.open}
      autoHideDuration={6000}
      onClose={() => showAlert('', 'info')}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={() => showAlert('', 'info')}
        severity={alertInfo.severity}
        sx={{ width: '100%' }}
      >
        {alertInfo.message}
      </Alert>
    </Snackbar>
  );
};

// ログインしていない時はROOTに遷移
// function PrivateRoute({ children }) {
//   const isLogin = useSelector(selectUserIsLogin);
//   return isLogin ? children : <Navigate to={PATH_ROOT} />;
// }
// ログインしていない時はROOTに遷移
function PrivateRoute({ children, requiredPermissions }) {
  const isLogin = useSelector(selectUserIsLogin);
  const location = useLocation();
  const userPermission = getUserPermission();

  if (!isLogin) {
    // ログイン前に試みたURLを保存
    localStorage.setItem('preLoginRoute', location.pathname);
    // ログインしていない場合、ログイン画面にリダイレクト
    return <Navigate to={PATH_ROOT} />;
  } else if (
    requiredPermissions &&
    !requiredPermissions.includes(userPermission)
  ) {
    // 必要な権限があり、かつユーザーの権限がそれと一致しない場合、トップ画面にリダイレクト
    return <Navigate to={PATH_TOP} />;
  }

  // 保存されたURLがあればそれにリダイレクト
  const savedRoute = localStorage.getItem('preLoginRoute') || PATH_TOP;
  return isLogin ? children : <Navigate to={savedRoute} />;
}

const AppContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  // ユーザーのログイン状態を監視しReduxに保存
  useEffect(() => {
    // ユーザーのログイン状態の変更を監視
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // ユーザーがログインしている場合
        dispatch(setIsLogin(true));

        // Firestoreからユーザー情報を取得してReduxに保存
        try {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // Dispatch action to save user data in Redux
            dispatch(setUserInfo(docSnap.data()));
            // user_at_last_loginを更新
            updateFirebaseUserAtLastLogin(docRef, docSnap.data());
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error(error);
        }

        // 保存されたURLがあればリダイレクト
        const savedRoute = localStorage.getItem('preLoginRoute') || PATH_TOP;
        navigate(savedRoute);
        localStorage.removeItem('preLoginRoute'); // 保存されたURLをクリア
      } else {
        // ユーザーがログアウトしている場合
        dispatch(setIsLogin(false));
      }

      setIsLoading(false);
    });

    // コンポーネントのクリーンアップ時に監視を解除
    return unsubscribe;
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <StyledApp className="app">
        <AlertProvider>
          <AlertSnackbar />
          <Header />
          <Routes>
            {/* 初期画面 */}
            <Route path={PATH_ROOT} element={<LoginScreen />} />
            {/* ログイン */}
            <Route path={PATH_LOGIN} element={<LoginScreen />} />
            {/* TODO ↓削除、外からアカウント作成見れないように */}
            {/*<Route path={PATH_SIGNUP} element={<SignUpScreen />} />*/}
            <Route
              path={PATH_SIGNUP}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR]}>
                  <SignUpScreen />
                </PrivateRoute>
              }
            />
            {/* 仮置き基板詳細ページ(基板IDのみ確認)、パスパラメータ:kibanIdを受け取る */}
            <Route path={PATH_KIBAN_DETAIL} element={<KibanDetailScreen />} />

            {/* ログイン状態でアクセスできるパス */}
            <Route
              path={PATH_TOP}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <TopScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_REGISTER_SUBSTRATE}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <RegisterSubstrateScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_BOARD_DETAILS}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <BoardDetails />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_REGISTER_SUBSTRATE_SUCCESS}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <RegisterSubstrateSuccessScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_PROCESS_LIST}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <ProcessListScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_L2_PROCESS_MASTER_LIST}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <L2ProcessMastarListScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_L2_TYPE_MASTER_LIST}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <L2TypeMastarListScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_MEASUREMENT_TYPE_MASTER_LIST}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <MeasurementTypeMastarListScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_EQUIMENT_TYPE_MASTER_LIST}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <EquipmentTypeMastarListScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_EQUIMENT_LIST}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <EquipmentListScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_EDIT_L2_TYPE_MASTER}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <EditL2TypeMastarScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_REGISTER_L2_TYPE_MASTER}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <RegisterL2TypeMastarScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_EDIT_MEASUREMENT_TYPE_MASTER}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <EditMeasurementTypeMastarScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_REGISTER_MEASUREMENT_TYPE_MASTER}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <RegisterMeasurementTypeMastarScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_CREATE_L2_PROCESS_MASTER}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <CreateL2ProcessMastarScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_L2_PROCESS_MASTER_DETAIL}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <L2ProcessMastarDetailScreen />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH_ACCOUNT}
              element={
                <PrivateRoute requiredPermissions={[SUPER_USER, ADMINISTRATOR, GENERAL_USER]}>
                  <AccountScreen />
                </PrivateRoute>
              }
            />
            {/* 全ての例外パスで下記スクリーンを描画 */}
            <Route path={PATH_OTHERS} element={<Navigate to={PATH_ROOT} />} />

            {/* TraceChainAmp配下以外はとりあえずROOTに、将来的には他のアプリに？ログインページに？ */}
            <Route path={PATH_ROOT_OUT} element={<Navigate to={PATH_ROOT} />} />
          </Routes>
        </AlertProvider>
      </StyledApp>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ja}
        dateFormats={{
          monthAndYear: 'yyyy/MM',
          year: 'yyyy',
        }} // カレンダー左上の日付表示 年選択を○○年表示
        localeText={{
          previousMonth: '前月を表示', // < のツールチップ
          nextMonth: '次月を表示', // > のツールチップ
          cancelButtonLabel: 'キャンセル', // スマホ画面のCANCELボタン
          okButtonLabel: '選択', // スマホ画面のOKボタン
        }}
      >
        <Router>
          <AppContainer />
        </Router>
      </LocalizationProvider>
    </Provider>
  );
}

const StyledApp = styled.div`
  background-color: ${COLOR_BACKGROUND_DARK_WHITE};
  min-height: 100vh;
`;

export default App;
