// @ts-check
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../constants/colors';
import { PATH_L2_PROCESS_MASTER_DETAIL } from '../consts/const';

const StyledTableContainer = styled(TableContainer)({
  flexGrow: 1,
  overflowX: 'auto',
  overflowY: 'auto',
});

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'component',
})(({ component }) => ({
  borderBottom: `1px solid ${COLORS.SECONDARY}`,
  whiteSpace: 'pre-wrap',
  textAlign: 'left',
  padding: '8px 16px',
  ...(component === 'th' && {
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.WHITE,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }),
}));

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5',
  },
  '&:hover': {
    backgroundColor: '#e8e8e8',
  },
});

const ActionButtonCell = styled(TableCell)({
  width: '250px',
  textAlign: 'center',
  padding: '8px 16px',
});

const ProcessListTable = ({
  columns,
  data,
  renderActions,
  onRowClick
}) => {
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    } else {
      navigate(PATH_L2_PROCESS_MASTER_DETAIL, { state: { data: row } });
    }
  };

  return (
    <StyledTableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <StyledTableCell key={col.header} component="th">
                {col.header}
              </StyledTableCell>
            ))}
            <StyledTableCell component="th" align="center">
              アクション
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((rowData, rowIndex) => (
            <StyledTableRow
              key={rowIndex}
              onClick={() => handleRowClick(rowData)}
              sx={{ cursor: 'pointer' }}
            >
              {columns.map((col, colIndex) => (
                <StyledTableCell key={colIndex}>
                  {col.render
                    ? col.render(rowData[col.field], rowData)
                    : rowData[col.field]}
                </StyledTableCell>
              ))}
              <ActionButtonCell onClick={(e) => e.stopPropagation()}>
                {renderActions(rowData)}
              </ActionButtonCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

ProcessListTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderActions: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
};

export default ProcessListTable;