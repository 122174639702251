// @ts-check
import { ArrowBack, Edit } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppHeader from '../../components/AppHeader';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_CREATE_L2_PROCESS_MASTER } from '../../consts/const';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ContentBox = styled(Box)({
  padding: '16px',
  flexGrow: 1,
  overflow: 'auto',
});

const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '8px',
});

const StyledButton = styled(Button)({
  backgroundColor: COLORS.SECONDARY,
  color: COLORS.BLACK,
  '&:hover': {
    backgroundColor: COLORS.TERTIARY,
  },
});

const L2ProcessMastarDetailScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [processData, setProcessData] = useState(null);

  useEffect(() => {
    if (location.state && location.state.data) {
      setProcessData(location.state.data);
    }
  }, [location]);

  const handleEdit = () => {
    navigate(PATH_CREATE_L2_PROCESS_MASTER, { state: { data: processData, mode: 'edit' } });
  };

  const handleDelete = () => {
    // 削除処理を実装
    console.log('Delete', processData);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (!processData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              L2プロセスマスタ詳細
            </Typography>
            <Box>
              <IconButton onClick={handleEdit} sx={{ color: 'white' }}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDelete} sx={{ color: 'white' }}>
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={handleCancel} sx={{ color: 'white' }}>
                <CancelIcon />
              </IconButton>
            </Box>
          </HeaderPaper>
          <ContentBox>
            <SectionTitle variant="subtitle1">基本項目</SectionTitle>
            <Divider sx={{ mb: 2 }} />
            <TextField
              label="タイトル"
              value={processData.title}
              variant="filled"
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="コメント"
              value={processData.comment}
              variant="filled"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              InputProps={{ readOnly: true }}
            />
            <SectionTitle variant="subtitle1" sx={{ mt: 2 }}>順序（編集不可）</SectionTitle>
            <Divider sx={{ mb: 2 }} />
            {processData.sequence.split('\n').map((item, index) => (
              <Paper key={index} sx={{ p: 1, mb: 1, backgroundColor: COLORS.SECONDARY }}>
                <Typography>{item}</Typography>
              </Paper>
            ))}
          </ContentBox>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, gap: 2, flexShrink: 0 }}>
            <Button
              sx={{ backgroundColor: COLORS.PRIMARY }}
              variant="contained"
              startIcon={<Edit />}
              onClick={handleEdit}
            >
              編集
            </Button>
            <StyledButton
              sx={{ backgroundColor: 'white', color: 'black' }}
              variant="contained"
              startIcon={<ArrowBack />}
              onClick={handleCancel}
            >
              取消
            </StyledButton>
          </Box>
        </MainContent>
      </Box>
    </Box>
  );
};

export default L2ProcessMastarDetailScreen;