import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetL2TypeMastar } from '../../api/GetL2TypeMastar';
import { Loading } from '../../commons/Loading';
import AppHeader from '../../components/AppHeader';
import MasterListTable from '../../components/MasterListTable';
import ReusableSearch from '../../components/ReusableSearch';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_EDIT_L2_TYPE_MASTER, PATH_REGISTER_L2_TYPE_MASTER } from '../../consts/const';
import { partialMatch } from '../../services/SerchService';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const columns = [
  { header: 'プロセスL2種別名', field: 'process_l2_type_name' },
];

const L2TypeMastarListScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchL2TypeMastar();
  }, []);

  const fetchL2TypeMastar = async () => {
    setIsLoading(true);
    try {
      const data = await GetL2TypeMastar();
      if (!data) {
        throw new Error('データの取得に失敗しました');
      }

      // レスポンスデータをそのまま使用
      setDisplayData(data);
      setOriginalData(data);
    } catch (error) {
      console.error('Failed to fetch L2 type master:', error);
      alert('L2種別マスタの取得に失敗しました: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setDisplayData(originalData);
      return;
    }

    const searchConditions = {
      process_l2_type_name: searchTerm
    };

    const filteredData = partialMatch(originalData, searchConditions);
    setDisplayData(filteredData);
  };

  const handleEdit = (masterItem) => {
    navigate(PATH_EDIT_L2_TYPE_MASTER, { state: { data: masterItem } });
  };

  const handleAdd = () => {
    navigate(PATH_REGISTER_L2_TYPE_MASTER);
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              L2種別マスタ一覧
            </Typography>
            <IconButton onClick={handleAdd} sx={{ color: 'white' }}>
              <AddIcon />
            </IconButton>
          </HeaderPaper>
          <ReusableSearch
            label="プロセスL2種別名"
            placeholder="プロセスL2種別名を入力"
            onSearch={(searchTerm) => handleSearch(searchTerm)}
          />
          <MasterListTable
            columns={columns}
            data={displayData}
            onEdit={handleEdit}
            emptyMessage="L2種別マスタデータがありません"
          />
        </MainContent>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default L2TypeMastarListScreen;