import { API_BASE_URL } from '../consts/const';
import { auth } from '../firebase';

const END_URL = 'substrates';

// 基板一覧を取得するメソッド
export const GetSubstrates = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User is not authenticated');
    }
    const idToken = await user.getIdToken();

    const response = await fetch(API_BASE_URL + END_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      }
    });

    if (!response.ok) {
      throw new Error(`HTTPエラー: ${response.status}`);
    }
    console.log('基板一覧の取得に成功しました。');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('API呼び出しエラー:', error);
  }
};
