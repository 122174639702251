import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LayersIcon from '@mui/icons-material/Layers';
import StorageIcon from '@mui/icons-material/Storage';
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../constants/colors';
import {
  PATH_EQUIMENT_LIST,
  PATH_EQUIMENT_TYPE_MASTER_LIST,
  PATH_L2_PROCESS_MASTER_LIST,
  PATH_L2_TYPE_MASTER_LIST,
  PATH_MEASUREMENT_TYPE_MASTER_LIST,
  PATH_PROCESS_LIST,
  PATH_REGISTER_SUBSTRATE,
  PATH_TOP
} from '../consts/const';

const StyledDrawer = styled(Drawer)({
  width: 240,
  '& .MuiDrawer-paper': {
    width: 240,
    boxSizing: 'border-box',
    backgroundColor: COLORS.SECONDARY,
    color: COLORS.BLACK,
    marginTop: 64,
    height: 'calc(100% - 64px)',
    position: 'fixed',
    padding: 0,
  },
});

const StyledListItemButton = styled(ListItemButton)({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '& .MuiListItemIcon-root': {
    color: COLORS.BLACK,
  },
});

const StyledListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
  },
});

const LogoutButton = styled(ListItemButton)({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
});

const menuItems = [
  {
    label: '基板',
    icon: <LayersIcon />,
    items: ['基板一覧', '基板登録']
  },
  // TODO(takahashi)リリースに含めないため
  // {
  //   label: '設計',
  //   icon: <DesignServicesIcon />,
  //   items: ['設計一覧', '基板設計組付']
  // },
  // {
  //   label: 'プロセス',
  //   icon: <BuildIcon />,
  //   items: ['プロセス一覧']
  // },
  {
    label: 'マスタ',
    icon: <StorageIcon />,
    items: ['L2種別マスタ一覧', '測定種別マスタ一覧', '装置種別マスタ一覧', '測定装置一覧']
    // items: ['L2プロセスマスタ一覧', 'L2種別マスタ一覧', '測定種別マスタ一覧', '装置種別マスタ一覧', '測定装置一覧']
  }
];

const Sidebar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState({});

  const handleClick = (label) => {
    setOpen({ ...open, [label]: !open[label] });
  };

  const handleSubItemClick = (item) => {
    console.log(item)
    switch (item) {
      case '基板一覧':
        navigate(PATH_TOP);
        break;
      case '基板登録':
        navigate(PATH_REGISTER_SUBSTRATE);
        break;
      case 'プロセス一覧':
        navigate(PATH_PROCESS_LIST);
        break;
      case 'L2プロセスマスタ一覧':
        navigate(PATH_L2_PROCESS_MASTER_LIST);
        break;
      case 'L2種別マスタ一覧':
        navigate(PATH_L2_TYPE_MASTER_LIST);
        break;
      case '測定種別マスタ一覧':
        navigate(PATH_MEASUREMENT_TYPE_MASTER_LIST);
        break;
      case '装置種別マスタ一覧':
        navigate(PATH_EQUIMENT_TYPE_MASTER_LIST);
        break;
      case '測定装置一覧':
        navigate(PATH_EQUIMENT_LIST);
        break;
      default:
        console.log("default");
        break;
    }
  };

  const handleLogout = () => {
    console.log('Logout clicked');
    // ここにログアウト処理を追加
  };

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Box>
        <List>
          {menuItems.map((menu) => (
            <React.Fragment key={menu.label}>
              <StyledListItemButton onClick={() => handleClick(menu.label)}>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <StyledListItemText primary={menu.label} />
                {open[menu.label] ? <ExpandLess /> : <ExpandMore />}
              </StyledListItemButton>
              <Collapse in={open[menu.label]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menu.items.map((item) => (
                    <StyledListItemButton sx={{ pl: 4 }} key={item} onClick={() => handleSubItemClick(item)}>
                      <StyledListItemText primary={item} />
                    </StyledListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
        <LogoutButton onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="ログアウト" />
        </LogoutButton>
      </Box>
    </StyledDrawer>
  );
};

export default Sidebar;