import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetMeasurementTypeMastar } from '../../api/GetMeasurementTypeMastar';
import AppHeader from '../../components/AppHeader';
import MasterListTable from '../../components/MasterListTable';
import ReusableSearch from '../../components/ReusableSearch';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_EDIT_MEASUREMENT_TYPE_MASTER, PATH_REGISTER_MEASUREMENT_TYPE_MASTER } from '../../consts/const';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

// カラム定義
const columns = [
  { header: 'ID', field: 'measure_type_id' },
  { header: '測定種別名', field: 'measure_type_name' },
  { header: '測定装置種別名', field: 'equipment_type_name' },
  { header: 'ファイル形式', field: 'file_format' },
];

const MeasurementTypeMastarListScreen = () => {
  const navigate = useNavigate();
  const [masterData, setMasterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // データ取得
  const fetchMasterData = async () => {
    setIsLoading(true);
    try {
      const response = await GetMeasurementTypeMastar();
      if (response) {
        setMasterData(response);
        setFilteredData(response);
      }
    } catch (error) {
      console.error('データ取得エラー:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMasterData();
  }, []);

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredData(masterData);
      return;
    }

    const filtered = masterData.filter(item =>
      item.measure_type_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleEdit = (masterItem) => {
    navigate(PATH_EDIT_MEASUREMENT_TYPE_MASTER, { state: { data: masterItem } });
  };

  const handleAdd = () => {
    navigate(PATH_REGISTER_MEASUREMENT_TYPE_MASTER);
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              測定種別マスタ一覧
            </Typography>
            <IconButton
              onClick={handleAdd}
              sx={{ color: 'white' }}
              disabled={isLoading}
            >
              <AddIcon />
            </IconButton>
          </HeaderPaper>
          <ReusableSearch
            label="測定種別名"
            placeholder="測定種別名"
            onSearch={handleSearch}
          />
          <MasterListTable
            columns={columns}
            data={filteredData}
            onEdit={handleEdit}
            emptyMessage="測定種別マスタデータがありません"
          />
        </MainContent>
      </Box>
    </Box>
  );
};

export default MeasurementTypeMastarListScreen;