import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export const fetchSizes = async () => {
    try {
        const constRef = collection(db, 'const');
        const sizeDoc = await getDocs(constRef);
        let sizes = [];

        sizeDoc.forEach((doc) => {
            if (doc.id === 'size') {
                const sizeData = doc.data();
                if (sizeData.sizes && Array.isArray(sizeData.sizes)) {
                    sizes = sizeData.sizes;
                }
            }
        });

        return sizes;
    } catch (error) {
        console.error('基板サイズの取得に失敗しました。:', error);
        return [];
    }
};

export const fetchLocations = async () => {
    try {
        const constRef = collection(db, 'const');
        const locationDoc = await getDocs(constRef);
        let locations = [];

        locationDoc.forEach((doc) => {
            if (doc.id === 'location') {
                const locationData = doc.data();
                if (locationData.locations && Array.isArray(locationData.locations)) {
                    locations = locationData.locations;
                }
            }
        });

        return locations;
    } catch (error) {
        console.error('所在地の取得に失敗しました。:', error);
        return [];
    }
};

export const fetchProjects = async () => {
    try {
        const constRef = collection(db, 'const');
        const projectnDoc = await getDocs(constRef);
        let projects = [];

        projectnDoc.forEach((doc) => {
            if (doc.id === 'project') {
                const projectData = doc.data();
                if (projectData.projects && Array.isArray(projectData.projects)) {
                    projects = projectData.projects;
                }
            }
        });

        return projects;
    } catch (error) {
        console.error('プロジェクトの取得に失敗しました。:', error);
        return [];
    }
};

export const fetchPlaneOrientation = async () => {
    try {
        const constRef = collection(db, 'const');
        const planeOrientationDoc = await getDocs(constRef);
        let planeOrientations = [];

        planeOrientationDoc.forEach((doc) => {
            if (doc.id === 'plane_orientation') {
                const planeOrientationData = doc.data();
                if (planeOrientationData.plane_orientations && Array.isArray(planeOrientationData.plane_orientations)
                ) {
                    planeOrientations = planeOrientationData.plane_orientations;
                }
            }
        });

        return planeOrientations;
    } catch (error) {
        console.error('面方位の取得に失敗しました。:', error);
        return [];
    }
};


export const fetchManufacturer = async () => {
    try {
        const constRef = collection(db, 'const');
        const manufacturerDoc = await getDocs(constRef);
        let manufactureres = [];

        manufacturerDoc.forEach((doc) => {
            if (doc.id === 'manufacturer') {
                const manufacturerData = doc.data();
                if (manufacturerData.manufactureres && Array.isArray(manufacturerData.manufactureres)
                ) {
                    manufactureres = manufacturerData.manufactureres;
                }
            }
        });

        return manufactureres;
    } catch (error) {
        console.error('メーカーの取得に失敗しました。:', error);
        return [];
    }
};

export const fetchPolishRequestDestination = async () => {
    try {
        const constRef = collection(db, 'const');
        const polishRequestDestinationDoc = await getDocs(constRef);
        let polishRequestDestination = [];

        polishRequestDestinationDoc.forEach((doc) => {
            if (doc.id === 'polish_request_destination') {
                const polishRequestDestinationData = doc.data();
                if (polishRequestDestinationData.polish_request_destinationes &&
                    Array.isArray(polishRequestDestinationData.polish_request_destinationes)
                ) {
                    polishRequestDestination = polishRequestDestinationData.polish_request_destinationes;
                }
            }
        });

        return polishRequestDestination;
    } catch (error) {
        console.error('研磨依頼先の取得に失敗しました。:', error);
        return [];
    }
};

export const fetchPrecisionPolishStatus = async () => {
    try {
        const constRef = collection(db, 'const');
        const precisionPolishStatusDoc = await getDocs(constRef);
        let precisionPolishStatuses = [];

        precisionPolishStatusDoc.forEach((doc) => {
            if (doc.id === 'precision_polish_status') {
                const precisionPolishStatusData = doc.data();
                if (precisionPolishStatusData.precision_polish_statuses &&
                    Array.isArray(precisionPolishStatusData.precision_polish_statuses)
                ) {
                    precisionPolishStatuses = precisionPolishStatusData.precision_polish_statuses;
                }
            }
        });

        return precisionPolishStatuses;
    } catch (error) {
        console.error('精密研磨状況の取得に失敗しました。:', error);
        return [];
    }
};