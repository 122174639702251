import React, { useState, createContext, useContext } from 'react';

import { TIME_ALERT_VIEW } from '../consts/const';

// AlertContext.jsx
const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alertInfo, setAlertInfo] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    const showAlert = (message, severity = 'info', time = TIME_ALERT_VIEW) => {
        setAlertInfo({ open: true, message, severity });
        setTimeout(() => {
            setAlertInfo((prev) => ({ ...prev, open: false }));
        }, time);
    };

    return (
        <AlertContext.Provider value={{ alertInfo, showAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);
