// @ts-check
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QrCodeIcon from '@mui/icons-material/QrCode';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetSubstrates } from '../../api/GetSubstrates';
import { PostSubstratesQR } from '../../api/PostSubstratesQR';
import { PutSubstrates } from '../../api/PutSubstrates';
import { Loading } from '../../commons/Loading';
import { COLORS } from '../../constants/colors';
import { Substrate } from '../../models/Substrate';
import { substrateListSearch } from '../../services/SerchService';
import { UserService } from '../../services/UserService';
import EditSection from './EditSection';
import SubstrateListModal from './SubstrateListModal';
import SubstrateListSearch from './SubstrateListSearch';
import { renderTableHeaders, renderTableRow } from './TableContent';

const BoardList = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isSearchExpanded, setIsSearchExpanded] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [editFormData, setEditFormData] = useState(null);
  const [users, setUsers] = useState([]);
  const [adminUseresName, setAdminUseresName] = useState([]);
  const [order, setOrder] = useState('asc');

  const navigate = useNavigate();

  useEffect(() => {
    fetchSubstrates();
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const fetchedUsers = await UserService.fetchAllUsers();
      setUsers(fetchedUsers);

      // 反転した名前の配列を生成
      const reversedNames = fetchedUsers.map(user =>
        UserService.getReversedFullName(user)
      );

      setAdminUseresName(reversedNames);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchSubstrates = async () => {
    setIsLoading(true);
    try {
      const data = await GetSubstrates();
      const substrateModels = data.map((item, index) => {
        const substrate = new Substrate(item);
        substrate.no = index + 1;
        return substrate;
      });
      setDisplayData(substrateModels);
      setOriginalData(substrateModels);
    } catch (error) {
      console.error('Failed to fetch substrates:', error);
      // エラー処理を追加
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(rowId => rowId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(displayData.map(item => item.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleCloseEdit = () => {
    setIsEditMode(false);
  };

  const handleQRClick = () => {
    setModalMode('qr');
    setModalOpen(true);
  };

  const handleBulkUpdate = (formData) => {
    setEditFormData(formData);
    setModalMode('edit');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleConfirmAction = async (data) => {
    if (modalMode === 'edit') {
      try {
        setIsLoading(true);

        // 管理者のuidを取得
        const administratorUid = editFormData.administrator
          ? await UserService.findUserIdByFullName(editFormData.administrator)
          : null;

        const updateData = {
          substrate_ids: selectedRows,
          project: editFormData.project || null,
          location: editFormData.location || null,
          administrator: administratorUid,
          remarks: editFormData.remarks || null
        };

        if (editFormData.administrator && !administratorUid) {
          throw new Error('指定された管理者が見つかりません');
        }

        await PutSubstrates(updateData);
        await fetchSubstrates();
        setIsEditMode(false);
        setModalOpen(false);
        alert('更新が完了しました。');
      } catch (error) {
        console.error('Failed to update substrates:', error);
        alert('更新に失敗しました: ' + error.message);
      } finally {
        setIsLoading(false);
      }
    } else if (modalMode === 'qr') {
      try {
        setIsLoading(true);
        // 選択された行のデータを取得
        const selectedSubstrates = displayData.filter(item => selectedRows.includes(item.id));

        // ファイル名用の日時文字列を生成（yyyymmddhh24mm形式）
        const now = new Date();
        const dateStr = now.getFullYear() +
          String(now.getMonth() + 1).padStart(2, '0') +
          String(now.getDate()).padStart(2, '0') +
          String(now.getHours()).padStart(2, '0') +
          String(now.getMinutes()).padStart(2, '0');

        // QRコードデータを取得してダウンロード
        const substrateIds = selectedSubstrates.map(sub => sub.id);
        const blob = await PostSubstratesQR(substrateIds, data.type);

        const fileDataType = data.type === 'テプラ' ? 'tepra' : 'laser';

        // 指定された形式のファイル名を生成
        const fileName = `${dateStr}_qrData_${fileDataType}.xlsx`;
        // ダウンロードリンクを作成
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // ダウンロードを実行
        document.body.appendChild(link);
        link.click();

        // クリーンアップ
        link.remove();
        window.URL.revokeObjectURL(url);

        console.log(`QR codes downloaded successfully as ${fileName}`);
      } catch (error) {
        console.error('Failed to download QR codes:', error);
        // エラーハンドリングを実装（例：エラーメッセージを表示）
      }
    }
    setIsLoading(false);
    setModalOpen(false);
    setIsEditMode(false);
  };

  const handleSearch = (searchConditions) => {
    const filteredData = substrateListSearch(originalData, searchConditions);
    setDisplayData(filteredData);
  };

  const cellStyle = {
    textAlign: 'center',
    verticalAlign: 'middle',
  };

  const handleDetails = (event, index) => {
    navigate(`/${displayData[index].id}`)
  }

  const handleSort = () => {
    // 現在の順序を反転
    setOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');

    // データを反転
    const sortedData = [...displayData].reverse();
    setDisplayData(sortedData);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden'
    }}>
      <Box sx={{ backgroundColor: COLORS.PRIMARY, padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white' }}>基板検索</Typography>
        <Button
          onClick={toggleSearch}
          startIcon={isSearchExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{ color: 'white' }}
        >
          {isSearchExpanded ? '検索条件を閉じる' : '検索条件を開く'}
        </Button>
      </Box>
      {isSearchExpanded && (
        <Box sx={{ maxHeight: '30%', overflow: 'auto' }}>
          <SubstrateListSearch
            onSearch={handleSearch}
            adminUseresName={adminUseresName}
          />
        </Box>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ flex: 1 }}>
          {['ステータス', '用途', '購入情報', '加工状況'].map((label, index) => (
            <Tab
              key={index}
              label={label}
              sx={{
                color: activeTab === index ? COLORS.WHITE : 'black',
                backgroundColor: activeTab === index ? COLORS.PRIMARY : COLORS.WHITE,
                '&.Mui-selected': { color: COLORS.WHITE }
              }}
            />
          ))}
        </Tabs>
        <IconButton onClick={handleQRClick}>
          <QrCodeIcon sx={{
            color: COLORS.PRIMARY,
            border: 2,
            borderColor: COLORS.PRIMARY,
          }} />
        </IconButton>
        <IconButton onClick={handleEditClick}>
          <EditIcon sx={{
            color: COLORS.PRIMARY,
            border: 2,
            borderColor: COLORS.PRIMARY,
          }} />
        </IconButton>
      </Box>
      <TableContainer sx={{
        flex: 1,
        overflow: 'auto',
        '& table': {
          minWidth: 650,
        }
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ ...cellStyle, backgroundColor: COLORS.PRIMARY, zIndex: 2 }}>
                <Checkbox
                  indeterminate={selectedRows.length > 0 && selectedRows.length < displayData.length}
                  checked={selectedRows.length === displayData.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              {renderTableHeaders(activeTab).map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    ...cellStyle,
                    backgroundColor: COLORS.PRIMARY,
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {header === '基板ID' ? (
                    <TableSortLabel
                      active={true}
                      direction={order}
                      onClick={handleSort}
                      sx={{
                        color: 'white !important',
                        '& .MuiTableSortLabel-icon': {
                          color: 'white !important',
                        },
                        '&.Mui-active': {
                          color: 'white !important',
                        },
                        '&:hover': {
                          color: 'white !important',
                        },
                      }}
                    >
                      基板ID
                    </TableSortLabel>
                  ) : (
                    header
                  )}
                </TableCell>
              ))}
              <TableCell
                sx={{ ...cellStyle, backgroundColor: COLORS.PRIMARY, color: COLORS.WHITE, fontWeight: 'bold' }}
              >
                操作
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((substrate, index) => (
              <TableRow key={substrate.id} sx={{ backgroundColor: index % 2 === 0 ? COLORS.WHITE : COLORS.GRAY_SECONDARY }}>
                <TableCell padding="checkbox" sx={cellStyle}>
                  <Checkbox
                    checked={selectedRows.includes(substrate.id)}
                    onChange={() => handleCheckboxChange(substrate.id)}
                  />
                </TableCell>
                {renderTableRow(activeTab, substrate).map((value, cellIndex) => (
                  <TableCell key={cellIndex} sx={cellStyle}>
                    {value}
                  </TableCell>
                ))}
                <TableCell sx={cellStyle}>
                  <Button onClick={(event) => handleDetails(event, index)} variant="outlined" size="small">
                    基板詳細
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditMode && (
        <EditSection
          selectedRows={selectedRows}
          data={displayData}
          onBulkUpdate={handleBulkUpdate}
          onClose={handleCloseEdit}
          adminUserNames={adminUseresName}
        />
      )}

      <SubstrateListModal
        open={modalOpen}
        handleClose={handleCloseModal}
        selectedRows={selectedRows}
        data={displayData}
        mode={modalMode}
        onConfirm={handleConfirmAction}
      />
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default BoardList;