// src/utils/uploadImageToFirebaseStorage.js

import { storage } from '../firebase';

import { IS_DEBUG } from '../consts/constDebug';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { getUserUid } from './getUserUid';
import { customConsole } from './customConsole';

// upload_path:`user_icon`など
export const uploadImageToFirebaseStorage = async (image, upload_path) => {
  if (!image) return;

  try {
    const uid = getUserUid();

    // 画像を Firebase Storage にアップロード
    const userIconRef = ref(storage, `users/${uid}/${upload_path}`);
    await uploadBytesResumable(userIconRef, image);

    // 画像のダウンロードURLを取得
    const imageUrl = await getDownloadURL(userIconRef);

    customConsole(() => {
      console.log(`Log:画像のアップロード完了`);
    });

    return imageUrl;
  } catch (error) {
    console.error('ユーザーデータの更新中にエラーが発生しました:', error);
  }
};
