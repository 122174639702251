import { ArrowBack } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdditionProcessMaster from '../../components/AdditionProcessMaster';
import AppHeader from '../../components/AppHeader';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_L2_PROCESS_MASTER_LIST } from '../../consts/const';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ContentBox = styled(Box)({
  padding: '16px',
  flexGrow: 1,
  overflow: 'auto',
});

const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '8px',
});

const StyledButton = styled(Button)({
  backgroundColor: COLORS.SECONDARY,
  color: COLORS.BLACK,
  '&:hover': {
    backgroundColor: COLORS.TERTIARY,
  },
});

const SequenceItem = styled(Paper)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 16px',
  marginBottom: '8px',
  backgroundColor: COLORS.SECONDARY,
});

const CreateL2ProcessMastarScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [processData, setProcessData] = useState({ title: '', comment: '', sequence: '' });
  const [mode, setMode] = useState('create');
  const [sequences, setSequences] = useState([]);
  const [isAddProcessOpen, setIsAddProcessOpen] = useState(false);

  useEffect(() => {
    if (location.state && location.state.data) {
      setProcessData(location.state.data);
      setMode(location.state.mode || 'edit');
      setSequences(location.state.data.sequence.split('\n'));
    }
  }, [location]);

  const handleSave = () => {
    // 保存処理を実装
    console.log('Save', processData);
    navigate(PATH_L2_PROCESS_MASTER_LIST);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    setProcessData({ ...processData, [e.target.name]: e.target.value });
  };

  const handleDeleteSequence = (index) => {
    const newSequences = sequences.filter((_, i) => i !== index);
    setSequences(newSequences);
    setProcessData({ ...processData, sequence: newSequences.join('\n') });
  };

  const handleAddProcess = (newProcess) => {
    const newSequences = [...sequences, newProcess];
    setSequences(newSequences);
    setProcessData({ ...processData, sequence: newSequences.join('\n') });
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              {mode === 'edit' ? 'L2プロセスマスタ編集' : 'L2プロセスマスタ作成'}
            </Typography>
            <Box>
              <IconButton onClick={handleSave} sx={{ color: 'white' }}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={handleCancel} sx={{ color: 'white' }}>
                <CancelIcon />
              </IconButton>
            </Box>
          </HeaderPaper>
          <ContentBox>
            <SectionTitle variant="subtitle1">基本項目</SectionTitle>
            <TextField
              label="タイトル"
              name="title"
              value={processData.title}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="コメント"
              name="comment"
              value={processData.comment}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            <SectionTitle variant="subtitle1" sx={{ mt: 2 }}>順序</SectionTitle>
            {sequences.map((seq, index) => (
              <SequenceItem key={index}>
                <Typography>{seq}</Typography>
                <IconButton onClick={() => handleDeleteSequence(index)} size="small">
                  <DeleteIcon />
                </IconButton>
              </SequenceItem>
            ))}
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setIsAddProcessOpen(true)}
              sx={{ mt: 2 }}
            >
              プロセス追加
            </Button>
            <AdditionProcessMaster
              open={isAddProcessOpen}
              onClose={() => setIsAddProcessOpen(false)}
              onAdd={handleAddProcess}
            />
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, gap: 2, flexShrink: 0 }}>
            <Button
              sx={{ backgroundColor: COLORS.PRIMARY }}
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            >
              保存
            </Button>
            <StyledButton
              sx={{ backgroundColor: 'white', color: 'black' }}
              variant="contained"
              startIcon={<ArrowBack />}
              onClick={handleCancel}
            >
              取消
            </StyledButton>
          </Box>
          </ContentBox>
        </MainContent>
      </Box>
    </Box>
  );
};

export default CreateL2ProcessMastarScreen;