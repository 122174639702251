// src/utils/customConsole.js

import { ENV_LOCAL, ENV_PRD } from '../consts/const';
import { IS_DEBUG } from '../consts/constDebug';

export function customConsole(logFunction, USE_IS_DEBUG = true) {
  const envName = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV
    : ENV_LOCAL;

  // PRD環境ではログを出さない
  if (envName == ENV_PRD) {
    return;
  }

  // DEBUG時のみ表示したい場合、IS_DEBUGがfalseの場合は出さない
  if (USE_IS_DEBUG && !IS_DEBUG) {
    return;
  }

  logFunction();
}
