import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';
import { COLORS } from '../constants/colors';

const StyledTableContainer = styled(TableContainer)({
  flexGrow: 1,
  overflowX: 'auto',
  overflowY: 'auto',
});

const StyledTableCell = styled(TableCell)(({ component }) => ({
  borderBottom: `1px solid ${COLORS.SECONDARY}`,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  ...(component === 'th' && {
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.WHITE,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }),
}));

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5',
  },
});

const EditButtonCell = styled(TableCell)({
  width: '50px',
  textAlign: 'center',
});

const EmptyMessageContainer = styled(TableRow)({
  height: '200px',
});

const EmptyMessageCell = styled(TableCell)({
  textAlign: 'center',
  color: '#666',
  fontSize: '1rem',
});

const MasterListTable = ({ columns, data, onEdit, emptyMessage = 'データがありません' }) => {
  const isEmpty = !data || data.length === 0;

  return (
    <StyledTableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <StyledTableCell key={col.header} component="th">
                {col.header}
              </StyledTableCell>
            ))}
            <StyledTableCell component="th" align="center">
              編集
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty ? (
            <EmptyMessageContainer>
              <EmptyMessageCell colSpan={columns.length + 1}>
                <Typography variant="body1">
                  {emptyMessage}
                </Typography>
              </EmptyMessageCell>
            </EmptyMessageContainer>
          ) : (
            data.map((rowData, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <StyledTableCell key={colIndex}>
                    {col.render
                      ? col.render(rowData[col.field], rowData)
                      : rowData[col.field]}
                  </StyledTableCell>
                ))}
                <EditButtonCell>
                  <IconButton
                    onClick={() => onEdit(rowData)}
                    size="small"
                    sx={{
                      color: COLORS.PRIMARY,
                      border: '1px solid #ccc',
                      borderColor: COLORS.PRIMARY,
                      borderRadius: '4px',
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </EditButtonCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

MasterListTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEdit: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string,
};

export default MasterListTable;