import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { COLOR_COMMON_1 } from '../consts/constCss';

export const Loading = ({ isLoading }) => {
  return (
    <Backdrop open={isLoading} style={{ color: COLOR_COMMON_1, zIndex: 1500 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
