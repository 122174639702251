// @ts-check
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../components/AppHeader';
import ProcessListTable from '../../components/ProcessListTable';
import ReusableSearch from '../../components/ReusableSearch';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_CREATE_L2_PROCESS_MASTER } from '../../consts/const';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

// カラム定義を修正
const columns = [
  { header: 'タイトル', field: 'title' },
  { header: '順序', field: 'sequence' },
  { header: 'コメント', field: 'comment' },
];

const L2ProcessMastarListScreen = () => {
  const navigate = useNavigate();

  const handleSearch = () => {
    // 検索処理を実装
  };

  const handleCopy = (row) => {
    // コピー処理を実装
    console.log('Copy', row);
  };

  const handleNew = () => {
    navigate(PATH_CREATE_L2_PROCESS_MASTER, { state: { mode: 'create' } });
  };

  // ダミーデータを修正
  const dummyData = [
    {
      title: '保護膜無し\nメッキなし\n通常プロセス',
      sequence: '序準備\n準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。'
    },
    {
      title: '保護膜あり\nメッキなし\n通常プロセス',
      sequence: '準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。'
    },
    {
      title: '保護膜あり\nメッキなし\n通常プロセス',
      sequence: '準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。'
    },
    {
      title: '保護膜あり\nメッキなし\n通常プロセス',
      sequence: '準備リンス1\nあれリンス2\nこれリンス3\n準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。'
    },
    {
      title: '保護膜あり\nメッキなし\n通常プロセス',
      sequence: '準備リンス1\nあれリンス2\nこれリンス3\n準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。'
    },
    {
      title: '保護膜あり\nメッキなし\n通常プロセス',
      sequence: '準備リンス1\nあれリンス2\nこれリンス3\n準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。'
    }
  ];

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              プロセス一覧
            </Typography>
            <IconButton onClick={handleNew} sx={{ color: 'white' }}>
              <AddIcon />
            </IconButton>
          </HeaderPaper>
          <ReusableSearch
            label="タイトル"
            placeholder="タイトルを入力"
            onSearch={handleSearch}
          />
          <ProcessListTable
            columns={columns}
            data={dummyData}
          />
        </MainContent>
      </Box>
    </Box>
  );
};

export default L2ProcessMastarListScreen;