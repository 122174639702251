import { Check, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostSubstratesQR } from '../../api/PostSubstratesQR';
import { Loading } from '../../commons/Loading';
import AppHeader from '../../components/AppHeader';
import CustomTable from '../../components/RegisterSubstrateTable';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_TOP } from '../../consts/const';
import { RegisteredSubstrate } from '../../models/RegisteredSubstrate';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const StyledButton = styled(Button)({
  backgroundColor: COLORS.SECONDARY,
  color: COLORS.BLACK,
  '&:hover': {
    backgroundColor: COLORS.TERTIARY,
  },
  width: 200
});

const RegisterSubstrateSuccessScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const substrates = (location.state?.substrates || []).map(sub => new RegisteredSubstrate(sub));
  const substrateIds = substrates.map(sub => sub.substrate_id);

  // カラム定義
  const columns = [
    { header: '基板ID', field: 'substrate_id' },
    { header: '基板サイズ', field: 'size' },
    { header: '厚さ（公称）', field: 'substrate_thickness' },
    { header: '面方位', field: 'plane_orientation' },
    { header: 'カット向き', field: 'cut_direction' },
    { header: 'メーカー', field: 'manufacturer' },
    { header: '代理店', field: 'agency' },
    { header: '製品名', field: 'product_name' },
    { header: '購入予算', field: 'purchase_budget' },
    { header: '精密研磨状況', field: 'processingHistory' },
    { header: '研磨依頼先', field: 'polish_request_destination' },
    { header: '研磨条件', field: 'polish_condition' },
    { header: '登録時備考', field: 'firstly_remarks' },
    { header: 'プロジェクト', field: 'project' },
    { header: '所在地', field: 'location' },
    { header: '管理者', field: 'administrator' },
    { header: '更新者', field: 'updated_by' },
    { header: '基板用途', field: 'usage' },
    { header: 'メモ・備考欄', field: 'remarks' },
  ];

  useEffect(() => {
    handleDownload();
  }, []);

  const handleDownload = async () => {
    try {
      setIsLoading(true);

      // ファイル名用の日時文字列を生成（yyyymmddhh24mm形式）
      const now = new Date();
      const dateStr = now.getFullYear() +
        String(now.getMonth() + 1).padStart(2, '0') +
        String(now.getDate()).padStart(2, '0') +
        String(now.getHours()).padStart(2, '0') +
        String(now.getMinutes()).padStart(2, '0');

      console.log(substrates);

      // QRコードデータを取得
      const tepraData = await PostSubstratesQR(substrateIds, 'テプラ');
      const laserData = await PostSubstratesQR(substrateIds, 'レーザー');

      // ファイル名を生成
      const tepraFileName = `${dateStr}_qrData_tepra.xlsx`;
      const laserFileName = `${dateStr}_qrData_laser.xlsx`;

      // テプラのダウンロード
      const tepraUrl = window.URL.createObjectURL(tepraData);
      const tepraLink = document.createElement('a');
      tepraLink.href = tepraUrl;
      tepraLink.setAttribute('download', tepraFileName);
      document.body.appendChild(tepraLink);
      tepraLink.click();
      document.body.removeChild(tepraLink);

      // 少し待機してから次のダウンロードを実行
      await new Promise(resolve => setTimeout(resolve, 100));

      // レーザーのダウンロード
      const laserUrl = window.URL.createObjectURL(laserData);
      const laserLink = document.createElement('a');
      laserLink.href = laserUrl;
      laserLink.setAttribute('download', laserFileName);
      document.body.appendChild(laserLink);
      laserLink.click();
      document.body.removeChild(laserLink);

      setTimeout(() => {
        window.URL.revokeObjectURL(tepraUrl);
        window.URL.revokeObjectURL(laserUrl);
      }, 100);

      console.log(`QR codes downloaded successfully as ${tepraFileName} and ${laserFileName}`);
    } catch (error) {
      console.error('Failed to download QR codes:', error);
      // エラーハンドリングを実装（例：エラーメッセージを表示）
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckClick = () => {
    navigate(PATH_TOP);
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <Paper sx={{ backgroundColor: COLORS.PRIMARY, padding: 2, flexShrink: 0 }}>
            <Typography variant="h6" sx={{ color: 'white' }}>
              基板登録
            </Typography>
          </Paper>
          <Box sx={{ p: 2, flexShrink: 0 }}>
            <Typography variant="h3" gutterBottom sx={{ color: 'red', marginBottom: 2 }}>
              基板登録完了
            </Typography>
            <Typography variant="h6" sx={{ color: 'black', marginBottom: 4 }}>
              画面下の確認ボタンを押すと。元の画面に戻ります。
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              基板基本情報一覧
            </Typography>
            <Divider sx={{ mb: 2 }} />
          </Box>
          <CustomTable columns={columns} data={substrates} />
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, gap: 4, flexShrink: 0 }}>
            <StyledButton
              sx={{ backgroundColor: COLORS.PRIMARY, color: COLORS.WHITE }}
              variant="contained"
              startIcon={<Check />}
              onClick={handleCheckClick}
            >
              確認
            </StyledButton>
            <StyledButton
              sx={{ backgroundColor: 'white', color: 'black' }}
              variant="contained"
              startIcon={<Download />}
              onClick={handleDownload}
            >
              再ダウンロード
            </StyledButton>
          </Box>
        </MainContent>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

export default RegisterSubstrateSuccessScreen;
