import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';

const CompletionModal = ({
  open,
  onClose,
  isSuccess,
  itemName,
  operation,
  onBackToList,
}) => {
  const message = `${itemName}の${operation}に${isSuccess ? '成功' : '失敗'}しました。`;
  const messageColor = isSuccess ? 'green' : 'red';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="h6"
          sx={{ color: messageColor, mb: 2 }}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onBackToList}
          variant="contained"
          startIcon={<ArrowBackIcon />}
          fullWidth
        >
          一覧に戻る
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompletionModal;
