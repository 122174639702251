import { CheckCircleOutline } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../../constants/colors';

const SubstrateListModal = ({ open, handleClose, selectedRows, data, mode, onConfirm }) => {
  const [selectedType, setSelectedType] = useState('');

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 1200,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column'
  };

  const cellStyle = {
    textAlign: 'center',
    padding: '6px',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.MuiTableCell-head': {
      fontWeight: 'bold',
      backgroundColor: COLORS.PRIMARY,
      color: 'white',
    },
  };

  const title = mode === 'edit'
    ? '以下の基板を更新します。よろしいですか？'
    : '以下の基板のQRコード用データをダウンロードします。よろしいですか？';

  const selectedData = data.filter(item => selectedRows.includes(item.id));

  const headers = [
    'No.',
    '基板ID',
    '設計',
    'ステータス',
    '更新者',
    '所在地',
    '厚さ(μm)',
    '面方位',
    'カット向き',
    'メーカー',
    '製品名',
    '登録時備考',
    '登録日',
    '更新日',
    '加工状況精密研磨状況',
    '研磨依頼先',
    '研磨者条件'
  ];

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleDownload = () => {
    if (selectedType) {
      onConfirm({ type: selectedType, selectedRows });
    }
    handleClose();
  };

  const handleEdit = () => {
    onConfirm();
    handleClose();
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" gutterBottom>
          {title}
        </Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 'calc(90vh - 200px)', overflow: 'auto' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index} sx={cellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedData.length > 0 ? (
                selectedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={cellStyle}>{row.no}</TableCell>
                    <TableCell sx={cellStyle}>{row.id}</TableCell>
                    <TableCell sx={cellStyle}>{row.designNo}</TableCell>
                    <TableCell sx={cellStyle}>{row.status}</TableCell>
                    <TableCell sx={cellStyle}>{row.updatedBy}</TableCell>
                    <TableCell sx={cellStyle}>{row.location}</TableCell>
                    <TableCell sx={cellStyle}>{row.thickness}</TableCell>
                    <TableCell sx={cellStyle}>{row.planeOrientation}</TableCell>
                    <TableCell sx={cellStyle}>{row.cutDirection}</TableCell>
                    <TableCell sx={cellStyle}>{row.manufacturer}</TableCell>
                    <TableCell sx={cellStyle}>{row.productName}</TableCell>
                    <TableCell sx={cellStyle}>{row.firstlyRemarks}</TableCell>
                    <TableCell sx={cellStyle}>{row.createdAt.toLocaleDateString()}</TableCell>
                    <TableCell sx={cellStyle}>{row.updatedAt.toLocaleDateString()}</TableCell>
                    <TableCell sx={cellStyle}>{row.processingHistory}</TableCell>
                    <TableCell sx={cellStyle}>{row.polishRequestDestination}</TableCell>
                    <TableCell sx={cellStyle}>{row.polishCondition}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} sx={cellStyle}>
                    データがありません
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {mode === 'qr' && (
          <Box sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <FormControl>
              <RadioGroup
                row
                value={selectedType}
                onChange={handleTypeChange}
              >
                <FormControlLabel
                  value="テプラ"
                  control={<Radio />}
                  label="テプラ"
                  sx={{ mx: 2 }}
                />
                <FormControlLabel
                  value="レーザー"
                  control={<Radio />}
                  label="レーザー刻印機"
                  sx={{ mx: 2 }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        <Box sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}>
          <Button
            variant="contained"
            startIcon={mode === 'edit' ? <CheckCircleOutline /> : <DownloadIcon />}
            onClick={mode === 'edit' ? handleEdit : handleDownload}
            disabled={mode === 'qr' && !selectedType}
            sx={{
              backgroundColor: COLORS.PRIMARY,
              color: COLORS.WHITE,
              '&:hover': {
                backgroundColor: COLORS.PRIMARY_DARK,
              },
              width: '45%',
            }}
          >
            {mode === 'edit' ? '更新' : 'ダウンロード'}
          </Button>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={handleClose}
            sx={{
              color: COLORS.PRIMARY,
              borderColor: COLORS.PRIMARY,
              '&:hover': {
                borderColor: COLORS.PRIMARY_DARK,
              },
              width: '45%',
            }}
          >
            戻る
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubstrateListModal; 