import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { User } from "../models/User";

export class UserService {
    static COLLECTION_NAME = 'users_public_profiles';

    /**
     * 全ユーザープロファイルを取得
     * @returns {Promise<Array<User>>} ユーザーオブジェクトの配列
     */
    static async fetchAllUsers() {
        try {
            const usersRef = collection(db, 'users_public_profiles');
            const snapshot = await getDocs(usersRef);

            return snapshot.docs
                .map(doc => new User({
                    uid: doc.id,
                    ...doc.data()
                }))
                .filter(user => user.userNameLast || user.userNameFirst);
        } catch (error) {
            console.error('ユーザーデータの取得に失敗しました:', error);
            throw error;
        }
    }

    /**
     * フルネームからユーザーを検索（反転形式対応）
     * @param {string} fullName - ユーザーのフルネーム（通常形式または反転形式）
     * @returns {Promise<string|null>} ユーザーのUID、見つからない場合はnull
     */
    static async findUserIdByFullName(fullName) {
        try {
            const users = await this.fetchAllUsers();
            // 入力された名前を分割
            const [part1, part2] = fullName.split(' ');

            // 通常形式と反転形式の両方で検索
            const user = users.find(user =>
                user.getFullName() === fullName || // 通常形式で検索
                `${user.userNameFirst} ${user.userNameLast}` === fullName // 反転形式で検索
            );

            return user ? user.uid : null;
        } catch (error) {
            console.error('Failed to find user by full name:', error);
            return null;
        }
    }

    /**
     * 表示用の反転フルネームを取得
     * @param {User} user - ユーザーオブジェクト
     * @returns {string} 反転したフルネーム
     */
    static getReversedFullName(user) {
        return `${user.userNameFirst} ${user.userNameLast}`;
    }
}
