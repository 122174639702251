// @ts-check
import CachedIcon from '@mui/icons-material/Cached';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Paper
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetSubstrateDetails } from '../api/GetSubstrateDetails';
import { Loading } from '../commons/Loading';
import { COLORS } from '../constants/colors';
import AppHeader from '../components/AppHeader';
import Sidebar from '../components/Sidebar';
import { PATH_TOP } from '../consts/const';

const BoardList = () => {
  const { substrate_id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const navigate = useNavigate();
  const [boardId, setBoardId] = useState('')
  const [displayData, setDisplayData] = useState({
    substrate_id: '',
    size: '',
    project: '',
    administrator: '',
    usage: '',
    location: '',
    purchase_budget: '',
    plane_orientation: '',
    substrate_thickness: '',
    manufacturer: '',
    agency: '',
    product_name: '',
    cut_direction: '',
    precision_polish_status: '',
    polish_request_destination: '',
    polish_condition: '',
    firstly_remarks: '',
    remarks: '',
    created_at: '',
    updated_at: ''
  });

  useEffect(() => {
    const pathSegment = window.location.pathname.split('/').pop();
    setBoardId(pathSegment);

    // ルートパス直下の先頭文字が数字以外の場合は基板IDでないので無効
    if (!/^\d/.test(pathSegment)) {
      navigate('/'); // ホームページにリダイレクト（または任意のリダイレクト先）
    }
  }, [navigate]);

  useEffect(() => {
    if (boardId) {
      fetchSubstrates();
    }
  }, [boardId])

  const MainContent = styled(Box)({
    backgroundColor: COLORS.BACKGROUND_GRAY,
    flexDirection: 'column',
    height: 'calc(100vh - 65px)',
    width: 'calc(100vw - 240px)',
    overflow: 'hidden',
    display: 'flex',
  });
  
  const HeaderPaper = styled(Paper)({
    backgroundColor: COLORS.PRIMARY,
    padding: '8px 16px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  });
  
  const ContentBox = styled(Box)({
    padding: '20px',
    flexGrow: 1,
    overflow: 'auto',
  });

  const FieldBox = styled(Typography)({
    background: COLORS.PRIMARY, 
    color: COLORS.WHITE, 
    padding: '16px',
    width: '50%'
  })

  const ValueBox = styled(Typography)({
    background: COLORS.WHITE, 
    padding: '16px', 
    width: '50%'
  })

  const fetchSubstrates = async () => {
    setIsLoading(true);
    try {
      // 対象IDで基板データ存在確認（無い場合はトップへ）
      const data = await GetSubstrateDetails(boardId);
      if (!data[0]) {
        alert('無効なURLです。')
        navigate(PATH_TOP)
      }

      setDisplayData(data[0]);
      setOriginalData(data[0]);
    } catch (error) {
      console.error('Failed to fetch substrates:', error);
      // エラー処理を追加
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleClose = () => {
    navigate(-1)
  }

  const boardDetailsData = {
    'プロジェクト': displayData.project,
    '管理者': displayData.administrator,
    '基板用途': displayData.usage,
    '所在地': displayData.location,
    '購入予算': displayData.purchase_budget,
    '面方位': displayData.plane_orientation,
    '厚さ（公称）': displayData.substrate_thickness,
    'メーカー': displayData.manufacturer,
    '代理店': displayData.agency,
    '製品名': displayData.product_name,
    'カット向き': displayData.cut_direction,
    '精密研磨状況': displayData.precision_polish_status ? '研磨済' : '未研磨',
    '研磨厚さ[μm]': '-',
    '研磨依頼先': displayData.polish_request_destination,
    '精密研磨条件': displayData.polish_condition,
  }

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <MainContent sx={{ width: '100%' }}>
        <AppHeader />
        <Box sx={{ backgroundColor: COLORS.PRIMARY, padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: 'white' }}>基板詳細</Typography>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={handleClose}
            sx={{
              color: COLORS.BLACK,
              background: COLORS.WHITE,
              width: '10%',
            }}
          >
            戻る
          </Button>
        </Box>
        <Box>
          <ContentBox sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', border: '1px solid black' }}>
              <Typography sx={{ background: COLORS.BLACK, color: COLORS.WHITE, padding: '16px' }}>基板ID</Typography>
              <Typography sx={{ padding: '16px' }}>{displayData.substrate_id}</Typography>
            </Box>
            <Typography sx={{ background: COLORS.PRIMARY, color: COLORS.WHITE, padding: '12px' }}>{displayData.size}</Typography>
          </ContentBox>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', px: '20px' }}>
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ flex: 1 }}>
            {/* {['基本情報', 'プロセス画像', 'プロセス結果'].map((label, index) => ( */} 
            {['基本情報'].map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{
                  color: activeTab === index ? COLORS.WHITE : 'black',
                  backgroundColor: activeTab === index ? COLORS.PRIMARY : COLORS.WHITE,
                  '&.Mui-selected': { color: COLORS.WHITE }
                }}
              />
            ))}
          </Tabs>
          {/* <IconButton 
            sx={{ display: 'flex', 
              gap: 2, 
              color: COLORS.PRIMARY, 
              border: 1, 
              borderColor: COLORS.PRIMARY,
              borderRadius: 0,
              mr: '20px',
              mb: '5px'
            }} >
            <CachedIcon />
            <Typography>ロット進捗確認</Typography>
          </IconButton> */}
          {/* <IconButton 
            sx={{ 
              color: COLORS.PRIMARY, 
              border: 1, 
              borderColor: COLORS.PRIMARY,
              borderRadius: 0,
              mb: '5px'
            }}>
            <Typography>測定結果登録</Typography>
          </IconButton> */}
        </Box>
        <ContentBox sx={{ background: '#e0e9f1', mx: '20px', mb: '20px', textAlign: 'center' }}>
          {activeTab === 0 && (
            <>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end', py: 2 }}>
                <Typography>作成日: {new Date(displayData.created_at).toLocaleDateString()}</Typography>
                <Typography>更新日: {new Date(displayData.updated_at).toLocaleDateString()}</Typography>
              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>  
              {Object.entries(boardDetailsData).map(([field, value]) => (
                <ContentBox key={field} sx={{ width: '90%', py: 1 }}>
                  <Box sx={{ display: 'flex' }}>
                    <FieldBox>{field}</FieldBox>
                    <ValueBox>
                      {value === undefined || value === null || value === '' ? '-' : typeof value === 'boolean' ? String(value) : value}
                    </ValueBox>
                  </Box>
                </ContentBox>
              ))}
              </Box>
              <ContentBox sx={{ textAlign: 'start' }}>
                <FieldBox sx={{ width: 'auto' }}>登録時備考</FieldBox>
                <ValueBox sx={{ width: 'auto' }}>{displayData.firstly_remarks}</ValueBox>
              </ContentBox>
              {Array.isArray(displayData.remarks) ? (
                <ContentBox sx={{ textAlign: 'start' }}>
                  <FieldBox sx={{ width: 'auto' }}>備考</FieldBox>
                  <ValueBox sx={{ width: 'auto', whiteSpace: 'pre-wrap' }}>
                    {displayData.remarks.join('\n')}
                  </ValueBox>
                </ContentBox>
              ) : (
                <ContentBox sx={{ textAlign: 'start' }}>
                  <FieldBox sx={{ width: '100%' }}>備考</FieldBox>
                  <ValueBox sx={{ width: '100%' }}>{displayData.remarks}</ValueBox>
                </ContentBox>
              )}
            </>
          )}
        </ContentBox>
        <Loading isLoading={isLoading} />
      </MainContent>
    </Box>
  );
};

export default BoardList;