import { setIsLogin } from '../redux/userDataSlice';
import { PATH_ROOT, PATH_TOP } from '../consts/const';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

export function login(dispatch, navigate, email, password) {
  if (!email || !password) {
    return;
  }
  signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // ログイン成功
      const user = userCredential.user;
      // ログイン状態を更新
      await dispatch(setIsLogin(true));

      // ホームページへリダイレクト
      navigate(PATH_TOP);
    })
    .catch((error) => {
      // ログイン失敗
      const errorCode = error.code;
      const errorMessage = error.message;

      // エラーコードに対応するメッセージを設定
      let displayMessage = '';
      switch (errorCode) {
        case 'auth/wrong-password':
          displayMessage = 'パスワードが間違っています。';
          break;
        case 'auth/user-not-found':
          displayMessage = 'このメールアドレスのユーザーは見つかりません。';
          break;
        default:
          displayMessage = errorMessage;
      }
      alert(displayMessage);
    });
}
