// src/components/SettingComponentBaseMinimum.js
import React from 'react';

import { Box, Typography, Grid } from '@mui/material';

import {
  FONT_SIZE_SETTING_TITLE,
  COLOR_BORDER,
  FONT_SIZE_SETTING_IS_PRIVATE,
  COLOR_COMMON_RED,
  FONT_SIZE_SETTING_ADD_DETAIL,
  COLOR_TEXT_COMMON_GRAY,
} from '../consts/constCss';
import { TEXT_NOT_VISIBLE_TO_OTHER_USERS } from '../consts/constText';

export const SettingComponentBaseMinimum = ({
  title,
  isViewPrivateText,
  supplementText,
  children,
}) => {
  return (
    <Box mb={3}>
      {/* ボーダー */}
      <Box bgcolor={COLOR_BORDER} height={2} mb={4}></Box>

      {/* タイトル */}
      <Grid container alignItems="flex-end">
        <Grid item>
          <Typography
            variant="h6"
            style={{
              fontSize: FONT_SIZE_SETTING_TITLE,
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
        </Grid>
        {isViewPrivateText && (
          <Grid item style={{ paddingLeft: '10px' }}>
            <Typography
              variant="h6"
              style={{
                fontSize: FONT_SIZE_SETTING_IS_PRIVATE,
                color: COLOR_COMMON_RED,
              }}
            >
              {TEXT_NOT_VISIBLE_TO_OTHER_USERS}
            </Typography>
          </Grid>
        )}
        {supplementText && (
          <Typography
            variant="standard"
            style={{
              fontSize: FONT_SIZE_SETTING_ADD_DETAIL,
              color: COLOR_TEXT_COMMON_GRAY,
              paddingLeft: '10px',
            }}
          >
            {supplementText}
          </Typography>
        )}
      </Grid>
      {children}
    </Box>
  );
};
