// src/components/Header.jsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';

import { Button } from '@mui/material';

import {
  ENV_LOCAL,
  ENV_TITLE_DEV,
  ENV_TITLE_LOCAL,
  ENV_TITLE_PRD,
  ENV_TITLE_STG,
  PATH_LOGIN,
  PATH_TOP
} from '../consts/const';
import { selectUserIsLogin } from '../redux/userDataSlice';

import { COLOR_BACKGROUND_WHITE } from '../consts/constCss';
import {
  TEXT_LOGIN
} from '../consts/constText';

import AccountMenu from './AccountMenu';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLogin = useSelector(selectUserIsLogin);

  const envName = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV
    : ENV_LOCAL;

  const envTitles = {
    LOCAL: ENV_TITLE_LOCAL,
    DEV: ENV_TITLE_DEV,
    STG: ENV_TITLE_STG,
    PRD: ENV_TITLE_PRD,
  };

  const envTitle = envTitles[envName];

  return (
    <StyledHeader
      className={`header ${!isLogin ? 'header-bottom-margin' : ''}`}
    >
      <StyledHeaderLeft>
        <div className="logo" onClick={() => navigate(PATH_TOP)}>
          TraceChainAmp {envTitle}
        </div>
      </StyledHeaderLeft>

      {!isLogin ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {location.pathname !== PATH_LOGIN && (
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={() => navigate(PATH_LOGIN)}
            >
              {TEXT_LOGIN}
            </StyledButton>
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {/* TODOodd 削除 */}
          {/* TODO 募集作成済みか否かでボタンの切り替え
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={() => navigate(PATH_RECRUIT_CREATE_START)}
          >
            {TEXT_CREATE_RECRUIT}
          </Button> */}
          <AccountMenu />
        </div>
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  /* headerの縦幅に合わせ、比率は固定 */
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px 6px 16px; // 右はボタンの分で0にし、アカウントボタンで調整
  background-color: ${COLOR_BACKGROUND_WHITE};

  /* 画面横幅に合わせる */
  width: 100%;
  box-sizing: border-box;

  /* 上部で固定 */
  position: sticky;
  top: 0;
  z-index: 1;

  /* ヘッダーの下に来る要素を下げる */
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    position: static; // 画面サイズが小さい時はヘッダーを固定しない
    padding: 0;
  }
`;

const StyledHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3.5px;

  img {
    height: 100%;
    max-width: 150px;
    max-height: 50px;
    object-fit: contain;
  }

  div {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    div {
      margin-right: 0px;
    }
    .link-text {
      display: none; // テキストを非表示に
    }
  }
`;

const StyledButton = styled(Button)`
  margin-right: 16px;

  @media screen and (max-width: 768px) {
    margin-right: 6px;
    padding: 5px 8px;
  }
`;

export default Header;
