import { API_BASE_URL } from '../consts/const';
import { auth } from '../firebase';

const END_URL = 'substrates/qr';

// QRを登録しQRデータを取得するメソッド
export const PostSubstratesQR = async (substrateIds, type) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error('User is not authenticated');
        }
        const idToken = await user.getIdToken();

        // APIリクエストボディの形式に合わせる
        const requestBody = {
            substrate_ids: substrateIds,
            type: type
        };

        const response = await fetch(`${API_BASE_URL}${END_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify(requestBody),
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `HTTPエラー: ${response.status}`);
        }

        // レスポンスをBlobとして取得（Excel形式）
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error('API呼び出しエラー:', error);
        throw error;
    }
};