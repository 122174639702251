export class RegisteredSubstrate {
  constructor({
    substrate_id = '',
    size = '',
    substrate_thickness = '',
    plane_orientation = '',
    cut_direction = '',
    manufacturer = '',
    agency = '',
    product_name = '',
    purchase_budget = '',
    precision_polish_status = '',
    polish_request_destination = '',
    polish_condition = '',
    firstly_remarks = '',
    project = '',
    location = '',
    administrator = '',
    updated_by = '',
    usage = '',
    remarks = '',
  }) {
    this.substrate_id = substrate_id;
    this.size = size;
    this.substrate_thickness = substrate_thickness;
    this.plane_orientation = plane_orientation;
    this.cut_direction = cut_direction;
    this.manufacturer = manufacturer;
    this.agency = agency;
    this.product_name = product_name;
    this.purchase_budget = purchase_budget;
    this.precision_polish_status = precision_polish_status;
    this.polish_request_destination = polish_request_destination;
    this.polish_condition = polish_condition;
    this.firstly_remarks = firstly_remarks;
    this.project = project;
    this.location = location;
    this.administrator = administrator;
    this.updated_by = updated_by;
    this.usage = usage;
    this.remarks = remarks;
  }

  get processingHistory() {
    return this.precision_polish_status ? '研磨済み' : '未研磨';
  }

  getScreenData() {
    return [
      this.substrate_id,
      this.size,
      this.substrate_thickness,
      this.plane_orientation,
      this.cut_direction,
      this.manufacturer,
      this.agency,
      this.product_name,
      this.purchase_budget,
      this.processingHistory,
      this.polish_request_destination,
      this.polish_condition,
      this.firstly_remarks,
      this.project,
      this.location,
      this.administrator,
      this.updated_by,
      this.usage,
      this.remarks,
    ];
  }
}