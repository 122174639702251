import { ArrowBack, Save, Upload } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetTemplateFile } from "../../api/GetTemplateFile";
import { postSubstrates } from '../../api/PostSubstrates';
import AppHeader from '../../components/AppHeader';
import RegisterSubstrateTable from '../../components/RegisterSubstrateTable';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_REGISTER_SUBSTRATE_SUCCESS } from '../../consts/const';
import { RegisteredSubstrate } from '../../models/RegisteredSubstrate';
import { formatExcelData, isExcelFile, readExcelFile } from '../../services/fileLeadService';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const StyledButton = styled(Button)({
  backgroundColor: COLORS.SECONDARY,
  color: COLORS.BLACK,
  '&:hover': {
    backgroundColor: COLORS.TERTIARY,
  },
});

const RegisterSubstrateScreen = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const [substrates, setSubstrates] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  // カラム定義
  const columns = [
    { header: '基板サイズ', field: 'size' },
    { header: '厚さ（公称）', field: 'substrate_thickness' },
    { header: '面方位', field: 'plane_orientation' },
    { header: 'カット向き', field: 'cut_direction' },
    { header: 'メーカー', field: 'manufacturer' },
    { header: '代理店', field: 'agency' },
    { header: '製品名', field: 'product_name' },
    { header: '購入予算', field: 'purchase_budget' },
    { header: '精密研磨状況', field: 'precision_polish_status' },
    { header: '研磨依頼先', field: 'polish_request_destination' },
    { header: '研磨条件', field: 'polish_condition' },
    { header: '登録時備考', field: 'firstly_remarks' },
    { header: 'プロジェクト', field: 'project' },
    { header: '所在地', field: 'location' },
    { header: '基板用途', field: 'usage' },
    { header: 'メモ・備考欄', field: 'remarks' },
  ];

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      // 前回のエラーやデータをリセット
      setErrorMessages([]);
      setSubstrates([]);
      setFileName('');
      setShowAlert(false);

      if (isExcelFile(file)) {
        try {
          const jsonData = await readExcelFile(file);
          const { substrates: parsedSubstrates, errors } = await formatExcelData(jsonData);

          if (errors.length > 0) {
            setShowAlert(true);
            setErrorMessages(errors);
          } else {
            setSubstrates(parsedSubstrates);
            setFileName(file.name);
            setShowAlert(false);
            setErrorMessages([]);
          }
        } catch (error) {
          setShowAlert(true);
          setErrorMessages([error.message]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setShowAlert(true);
        setErrorMessages(['無効なファイル形式です。xlsxファイルをアップロードしてください。']);
        setIsLoading(false);
      }
    }
  };

  // 画面のリセット処理
  const handleCancel = () => {
    setFileName('');
    setSubstrates([]);
    setErrorMessages([]);
    setShowAlert(false);
  };

  const handleRegister = async () => {
    setIsPosting(true);
    setErrorMessages([]);
    setShowAlert(false);

    try {
      const responseData = await postSubstrates(substrates);
      // 成功した場合、確認画面に遷移し、データを渡す
      const registeredSubstrates = responseData.map(sub => new RegisteredSubstrate(sub));
      navigate(PATH_REGISTER_SUBSTRATE_SUCCESS, { state: { substrates: registeredSubstrates } });
    } catch (error) {
      // エラーが発生した場合、エラーメッセージを表示
      setShowAlert(true);
      setErrorMessages([error.message]);
    } finally {
      setIsPosting(false);
    }
  };

  const handleDownloadTemplate = async () => {
    await GetTemplateFile()
  }


  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <Paper sx={{ backgroundColor: COLORS.PRIMARY, padding: 2, flexShrink: 0 }}>
            <Typography variant="h6" sx={{ color: 'white' }}>
              基板登録
            </Typography>
          </Paper>
          <Box sx={{ p: 2, flexShrink: 0 }}>
            <Typography variant="subtitle1" gutterBottom>
              ファイル
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <TextField
                value={fileName}
                placeholder="ファイル名"
                InputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
              <StyledButton
                variant="contained"
                as="label"
                sx={{ minWidth: 'auto', padding: '8px' }}
              >
                <Upload />
                <input type="file" hidden onChange={handleFileUpload} />
              </StyledButton>
              <StyledButton
                variant="contained"
                startIcon={<FileDownloadIcon />}
                onClick={handleDownloadTemplate}
              >
                テンプレートダウンロード
              </StyledButton>
            </Box>
            <Typography variant="subtitle1" gutterBottom>
              基板基本情報
            </Typography>
            <Divider sx={{ mb: 2 }} />
          </Box>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
              <CircularProgress />
            </Box>
          ) : substrates.length > 0 ? (
            <RegisterSubstrateTable columns={columns} data={substrates} />
          ) : null}
          {!isLoading && substrates.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, gap: 4, flexShrink: 0 }}>
              <StyledButton
                sx={{ backgroundColor: 'white', color: 'black' }}
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={handleCancel}
              >
                取り消し
              </StyledButton>
              <Button
                sx={{ backgroundColor: COLORS.PRIMARY }}
                variant="contained"
                startIcon={<Save />}
                onClick={handleRegister}
                disabled={isPosting}
              >
                {isPosting ? <CircularProgress size={24} /> : '登録'}
              </Button>
            </Box>
          )}
        </MainContent>
      </Box>
      {showAlert && errorMessages.length > 0 && (
        <Alert
          severity="error"
          onClose={() => setShowAlert(false)}
          sx={{ position: 'fixed', bottom: 16, right: 16, maxWidth: '400px', overflowY: 'auto', maxHeight: '50vh' }}
        >
          <AlertTitle>エラーが発生しました</AlertTitle>
          <ul>
            {errorMessages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        </Alert>
      )}
    </Box>
  );
};

export default RegisterSubstrateScreen;