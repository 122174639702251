import styled from '@emotion/styled';
import { Box, Paper } from '@mui/material';
import React from 'react';
import AppHeader from '../components/AppHeader';
import Sidebar from '../components/Sidebar';
import BoardList from '../components/TopScreen/BoardList';

const TopScreenContainer = styled(Box)({
  display: 'flex',
  flex: 1,
  height: 'calc(100vh - 65px)'
});

const MainContent = styled(Box)({
  flexGrow: 1,
  overflow: 'hidden',
});

const TopScreen = () => {
  return (
    <Paper sx={{
      height: 'calc(100vh - 65px)',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <AppHeader />
      <TopScreenContainer>
        <Sidebar />
        <MainContent>
          <BoardList />
        </MainContent>
      </TopScreenContainer>
    </Paper>
  );
};

export default TopScreen;