import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, MenuItem, Paper, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { fetchLocations, fetchSizes } from '../../functions/fetchFireStore';

const SubstrateListSearch = ({ onSearch, adminUseresName }) => {
  const [searchConditions, setSearchConditions] = useState({
    id: '',
    designNo: '',
    size: '',
    administrator: '',
    location: ''
  });
  const [substrateSizeOptions, setsSubstrateSizeOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchConditions(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    onSearch(searchConditions);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [sizes, locations] = await Promise.all([
          fetchSizes(),
          fetchLocations()
        ]);

        setsSubstrateSizeOptions(sizes);
        setLocationOptions(locations);
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Paper sx={{ padding: 2, backgroundColor: '#f0f0f0' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            name="id"
            label="基板ID"
            variant="outlined"
            value={searchConditions.id}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            select
            name="size"
            label="基板サイズ"
            variant="outlined"
            value={searchConditions.size}
            onChange={handleInputChange}
          >
            <MenuItem value="">
              <em>選択なし</em>
            </MenuItem>
            {substrateSizeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            name="designNo"
            label="設計"
            variant="outlined"
            value={searchConditions.designNo}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            select
            name="administrator"
            label="管理者"
            variant="outlined"
            value={searchConditions.administrator}
            onChange={handleInputChange}
          >
            <MenuItem value="">
              <em>選択なし</em>
            </MenuItem>
            {adminUseresName.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <TextField
            fullWidth
            select
            name="location"
            label="所在地"
            variant="outlined"
            value={searchConditions.location}
            onChange={handleInputChange}
          >
            <MenuItem value="">
              <em>選択なし</em>
            </MenuItem>
            {locationOptions.map((location) => (
              <MenuItem key={location} value={location}>
                {location}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            fullWidth
            variant="contained"
            sx={{ backgroundColor: COLORS.PRIMARY, height: '56px' }}
            onClick={handleSearch}
            startIcon={<SearchIcon />}
          >
            検索
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

SubstrateListSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  adminUserNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SubstrateListSearch;