import { API_BASE_URL } from '../consts/const';
import { auth } from '../firebase';

const END_URL = 'measure_types';

// 測定種別マスタ一を新規登録するメソッド
export const PostMeasureTypes = async (requestData) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error('User is not authenticated');
        }
        const idToken = await user.getIdToken();

        // リクエストボディを配列形式に変換
        const requestBody = [
            {
                measure_type_id: requestData.measure_type_id,
                measure_type_name: requestData.measure_type_name,
                equipment_type_id: requestData.equipment_type_id,
            }
        ];

        const response = await fetch(`${API_BASE_URL}${END_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify(requestBody),
            credentials: 'include',
        });

        if (!response.ok) {
            // サーバーからのエラーメッセージを取得
            const errorData = await response.json();
            throw new Error(errorData.message || `HTTPエラー: ${response.status}`);
        }

        console.log('データの登録に成功しました。');
        const data = await response.json();
        return data; // レスポンスデータを返す
    } catch (error) {
        console.error('API呼び出しエラー:', error);
        throw error; // エラーを再スローして呼び出し元で処理
    }
};
