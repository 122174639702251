// src/utils/updateFirebaseAndReduxUserInfo.js

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

import { setUserInfo } from '../redux/userDataSlice';

import { _ } from '../consts/const';

import { IS_DEBUG } from '../consts/constDebug';
import { getUserUid } from './getUserUid';
import { customConsole } from './customConsole';

export const updateFirebaseAndReduxUserInfo = async (
  dispatch,
  baseKey,
  targetObject
) => {
  if (!baseKey) return;

  const uid = getUserUid();

  try {
    const userDocRef = doc(db, `users/${uid}`);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      let oldUserInfo = docSnap.data();
      let newUserInfo = _.cloneDeep(oldUserInfo);

      // データを更新
      _.set(newUserInfo, baseKey, targetObject);

      // 更新日を追加
      _.set(newUserInfo, 'user_at_updated', new Date().toISOString());

      await updateDoc(userDocRef, newUserInfo);

      dispatch(setUserInfo(newUserInfo));

      // ユーザー名とアイコンが変更された時のみ、ユーザーの公開情報を更新
      if (['user_name', 'user_icon'].includes(baseKey)) {
        const userPublicRef = doc(db, 'users_public_profiles', `${uid}`);
        let newUserInfoPublic = {
          uid: uid,
          user_name: newUserInfo.user_name,
          user_icon: newUserInfo.user_icon,
        };

        await updateDoc(userPublicRef, newUserInfoPublic);
      }

      // ユーザー名が変更された場合
      if (baseKey === 'user_name') {
        // ユーザーの募集と募集も更新
        updateSavedRecruits(userDocRef, newUserInfo, 'user_name');
      }

      // ユーザーアイコンが更新され、かつ初めてのアイコン更新の場合
      // （アイコンのパスは画像を変更しても変わらないため、初回のみでOK）
      if (baseKey === 'user_icon' && !oldUserInfo.user_icon) {
        // ユーザーの募集と募集も更新
        // TODO 基板情報も同様に変更予定
        updateSavedRecruits(userDocRef, newUserInfo, 'user_icon');
      }

      // TODO 年齢と性別も初回のみ更新するようにする
      customConsole(() => {
        console.log(`Log: ユーザーデータ更新完了: ${baseKey}`);
      });
    } else {
      console.error(`ユーザーのドキュメントが見つかりませんでした: ${uid}`);
    }
  } catch (error) {
    console.error('ユーザーデータの更新中にエラーが発生しました:', error);
  }
};

// TODO 基板情報も同様に管理
// ユーザーの募集(firestore/users/{uid}/recruits/{target})と、募集の内容(firestore/recruits)を更新する。
const updateSavedRecruits = async (userDocRef, newUserInfo, target) => {
  let newUserInfoCloned = _.cloneDeep(newUserInfo);
  newUserInfoCloned.recruits = _.mapValues(newUserInfo.recruits, (recruit) => {
    return { ...recruit, [target]: newUserInfo[target] };
  });

  await updateDoc(userDocRef, newUserInfoCloned);

  // 合わせてfirestore/recruitsそれぞれのユーザーアイコンも更新
  _.forEach(newUserInfoCloned.recruits, async (value, key) => {
    const updatedValue = {
      ...value,
      [target]: newUserInfo[target],
    };

    const recruitDocRef = doc(
      db,
      `recruits/${value.recruit_type}/${value.recruit_type_for}/${key}`
    );

    await updateDoc(recruitDocRef, updatedValue);
  });
};
