import { API_BASE_URL } from '../consts/const';
import { auth } from '../firebase';
import { RegisterSubstrate } from '../models/RegisterSubstrate';

const END_URL = 'substrates';

// 基板一覧を登録するメソッド
export const postSubstrates = async (substrates) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User is not authenticated');
    }
    const idToken = await user.getIdToken();
    const convertedSubstrates = substrates.map(substrate =>
      new RegisterSubstrate(substrate).toApiFormat());
    const response = await fetch(`${API_BASE_URL}${END_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
      body: JSON.stringify(convertedSubstrates),
      credentials: 'include',
    });

    if (!response.ok) {
      // サーバーからのエラーメッセージを取得
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTPエラー: ${response.status}`);
    }

    console.log('データの登録に成功しました。');
    const data = await response.json();
    return data; // レスポンスデータを返す
  } catch (error) {
    console.error('API呼び出しエラー:', error);
    throw error; // エラーを再スローして呼び出し元で処理
  }
};
