// src/screens/SignUpScreen.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAlert } from '../commons/AlertContext';
import { signUp } from '../functions/signUp';

import {
  Button,
  TextField,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import ScreenBaseComponentM from '../components/ScreenBaseComponentM';

import {
  TEXT_AGREE,
  TEXT_ALERT_SIGN_UP_FAILED,
  TEXT_ALERT_SIGN_UP_SUCCESS,
  TEXT_BELONG_TO,
  TEXT_KANA_FIRST,
  TEXT_KANA_LAST,
  TEXT_NAME_FIRST,
  TEXT_NAME_LAST,
  TEXT_PASSWORD,
  TEXT_REQUIRED_OF_THIS,
  TEXT_TITLE_SIGN_UP,
  TEXT_VALIDATE_EMAIL,
  TEXT_VALIDATE_KANA,
  TEXT_VALIDATE_PASSWORD,
} from '../consts/constText';

import { BELONG_TO_OPTIONS, PATH_USER_LIST } from '../consts/const';
import {
  COLOR_MUI_DEFAULT,
  COLOR_MUI_FOCUSED,
  COLOR_MUI_VALIDATION,
} from '../consts/constCss';
import { Loading } from '../commons/Loading';

const SignUpScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [formValues, setFormValues] = useState({
    email: '',
    name_first: '',
    name_last: '',
    kana_first: '',
    kana_last: '',
    belongTo: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    name_first: '',
    name_last: '',
    kana_first: '',
    kana_last: '',
    belongTo: '',
    password: '',
  });

  const [isFocused, setIsFocused] = useState(false); // フォーカス状態を追跡するためのステート、セレクトボックス用
  const [isLoading, setIsLoading] = useState(false); // ローディング状態を追跡するためのステート

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    // stateを更新。
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // バリデーション関数を呼び出し。
    validate(name, value);
  };

  // フォームのバリデーションを行う
  const validate = (name, value) => {
    let error = '';

    // valueが空文字なら、エラーメッセージを設定
    if (value === '') {
      error = TEXT_REQUIRED_OF_THIS;
    }

    // メールアドレスフィールドのためのカスタムバリデーション
    if (name === 'email' && value !== '') {
      if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
        error = TEXT_VALIDATE_EMAIL;
      }
    }

    // 振り仮名フィールドのためのカスタムバリデーション
    if ((name === 'kana_first' || name === 'kana_last') && value !== '') {
      // 全角カタカナ以外の文字が含まれているかチェック
      if (!value.match(/^[ァ-ヶー　]*$/)) {
        error = TEXT_VALIDATE_KANA;
      }
    }

    // TODO コメントアウトをなくす
    // パスワードフィールドのためのカスタムバリデーション
    // if (name === 'password' && value !== '') {
    //   if (
    //     !value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{12,}$/)
    //   ) {
    //     error = TEXT_VALIDATE_PASSWORD;
    //   }
    // }

    // バリデーション結果（エラーメッセージ）をstateに保存
    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // ページ遷移を防ぐ

    // すべてのフィールドをバリデーション
    Object.keys(formValues).forEach((field) => {
      validate(field, formValues[field]);
    });

    // すべてのフィールドが正しく入力されているかを確認
    const isValidForm = Object.values(formErrors).every(
        (error) => error === ''
    );

    // バリデーションに失敗した場合は処理を中断
    if (!isValidForm) {
      return;
    }

    //ユーザー登録処理
    setIsLoading(true);

    const isResult = await signUp(formValues);
    if (isResult) {
      // ユーザー一覧画面に遷移
      setIsLoading(false);
      navigate(PATH_USER_LIST);
      showAlert(TEXT_ALERT_SIGN_UP_SUCCESS, 'success'); // アラート表示
    } else {
      setIsLoading(false);
      showAlert(TEXT_ALERT_SIGN_UP_FAILED, 'error'); // アラート表示
    }
  };

  return (
      <ScreenBaseComponentM title={TEXT_TITLE_SIGN_UP}>
        <Loading isLoading={isLoading} />
        <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              mt: 3,
            }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                  required
                  fullWidth
                  id="email"
                  label="メールアドレス"
                  name="email"
                  autoComplete="email"
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  value={formValues.email}
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                  required
                  fullWidth
                  id="name_first"
                  label={TEXT_NAME_FIRST}
                  name="name_first"
                  error={!!formErrors.name_first}
                  helperText={formErrors.name_first}
                  value={formValues.name_first}
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                  required
                  fullWidth
                  id="name_last"
                  label={TEXT_NAME_LAST}
                  name="name_last"
                  error={!!formErrors.name_last}
                  helperText={formErrors.name_last}
                  value={formValues.name_last}
                  onChange={handleChange}
              />
            </Grid>
            {/* カナ(姓) */}
            <Grid item xs={6}>
              <TextField
                  required
                  fullWidth
                  id="kana_first"
                  label={TEXT_KANA_FIRST}
                  name="kana_first"
                  error={!!formErrors.kana_first}
                  helperText={formErrors.kana_first}
                  value={formValues.kana_first}
                  onChange={handleChange}
              />
            </Grid>
            {/* カナ(名) */}
            <Grid item xs={6}>
              <TextField
                  required
                  fullWidth
                  id="kana_last"
                  label={TEXT_KANA_LAST}
                  name="kana_last"
                  error={!!formErrors.kana_last}
                  helperText={formErrors.kana_last}
                  value={formValues.kana_last}
                  onChange={handleChange}
              />
            </Grid>
            {/* 所属 */}
            <Grid item xs={12}>
              <FormControl
                  fullWidth
                  error={!!formErrors.belongTo}
                  variant="outlined"
              >
                <InputLabel
                    id="belongTo-label"
                    style={{
                      color: formErrors.belongTo
                          ? COLOR_MUI_VALIDATION
                          : isFocused
                              ? COLOR_MUI_FOCUSED
                              : COLOR_MUI_DEFAULT,
                    }}
                >
                  {TEXT_BELONG_TO}*
                </InputLabel>
                <Select
                    labelId="belongTo-label"
                    id="belongTo"
                    name="belongTo"
                    value={formValues.belongTo}
                    label={TEXT_BELONG_TO}
                    onChange={handleChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    error={!!formErrors.belongTo}
                    style={{
                      borderColor: formErrors.belongTo
                          ? COLOR_MUI_VALIDATION
                          : isFocused
                              ? COLOR_MUI_FOCUSED
                              : COLOR_MUI_DEFAULT,
                    }}
                >
                  {BELONG_TO_OPTIONS.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name} ({option.label})
                      </MenuItem>
                  ))}
                </Select>
                {!!formErrors.belongTo && (
                    // pタグのStyleはMaterial-UIのデフォルトのhelperTextに合わせて調整済み
                    <p
                        style={{
                          color: formErrors.belongTo
                              ? COLOR_MUI_VALIDATION
                              : COLOR_MUI_DEFAULT,
                          marginTop: '8px',
                          fontSize: '0.75rem',
                          lineHeight: '1.66',
                          letterSpacing: '0.03333em',
                          marginBottom: '0',
                        }}
                    >
                      {formErrors.belongTo}
                    </p>
                )}
              </FormControl>
            </Grid>
            {/* パスワード */}
            <Grid item xs={12}>
              <TextField
                  required
                  fullWidth
                  name="password"
                  label={TEXT_PASSWORD}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={!!formErrors.password}
                  helperText={formErrors.password}
                  value={formValues.password}
                  onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{ mt: 3, mb: 2 }}
          >
            {TEXT_AGREE}
          </Button>
        </Box>
      </ScreenBaseComponentM>
  );
};

export default SignUpScreen;
