// src/components/AccountSettingCommon.js
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../redux/userDataSlice';

import { SettingComponentTextInput } from './SettingComponentTextInput';
import { SettingComponentAccountIconChange } from './SettingComponentAccountIconChange';
import { TEXT_LIMIT_NUM_COMMON_50 } from '../consts/const';

const AccountSettingCommon = ({ initialChips }) => {
  const userInfo = useSelector(selectUserInfo);

  if (!userInfo) {
    return;
  }

  return (
    <>
      <SettingComponentTextInput
        title="ユーザー名"
        baseKey={'user_name'}
        targetTextLimit={TEXT_LIMIT_NUM_COMMON_50}
        isRequired={true}
      />
      <SettingComponentAccountIconChange
        title="アイコン"
        baseKey={'user_icon'}
      />
    </>
  );
};

export default AccountSettingCommon;
