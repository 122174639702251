import * as XLSX from 'xlsx';
import { fetchLocations, fetchManufacturer, fetchPlaneOrientation, fetchPolishRequestDestination, fetchPrecisionPolishStatus, fetchProjects, fetchSizes } from '../functions/fetchFireStore';
import { RegisterSubstrate } from '../models/RegisterSubstrate';

// 定数値を取得する関数
export const getValidValues = async () => {
  try {
    const [
      sizes,
      orientations,
      manufacturers,
      polishRequests,
      projects,
      locations,
      precisionPolishStatuses
    ] = await Promise.all([
      fetchSizes(),
      fetchPlaneOrientation(),
      fetchManufacturer(),
      fetchPolishRequestDestination(),
      fetchProjects(),
      fetchLocations(),
      fetchPrecisionPolishStatus()
    ]);

    return {
      boardSize: sizes,
      orientation: orientations,
      manufacturer: manufacturers,
      polishingRequest: polishRequests,
      project: projects,
      location: locations,
      precisionPolishStatus: precisionPolishStatuses
    };
  } catch (error) {
    console.error('定数の取得に失敗しました:', error);
    return null;
  }
};


// Excel ファイルかどうかを判断する関数
export const isExcelFile = (file) => {
  return file.name.endsWith('.xlsx');
};

// Excel ファイルを読み込む関数
export const readExcelFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        resolve(jsonData);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

// Excel データを Substrate オブジェクトの配列に変換する関数
export const formatExcelData = async (data) => {
  const substrates = [];
  const errors = [];
  const startIndex = 1; // データ開始行（ヘッダーを飛ばす）
  const validValues = await getValidValues();
  if (!validValues) {
    return {
      substrates: [],
      errors: ['定数の取得に失敗しました。システム管理者に連絡してください。']
    };
  }

  for (let i = startIndex; i < data.length; i++) {
    const row = data[i];

    // 1列目（No列）を除いた行データ
    const rowData = row.slice(1);

    // 行が空白の場合、それ以降は描画しない
    const isEmptyRow = rowData.every(
      (cell) =>
        cell === undefined ||
        cell === null ||
        (typeof cell === 'string' && cell.trim() === '')
    );

    if (isEmptyRow) {
      break;
    }

    // 必須項目の取得
    const boardSize = row[1] ? row[1].toString().trim() : '';
    const manufacturer = row[5] ? row[5].toString().trim() : '';

    // 必須項目の確認
    if (!boardSize || !manufacturer) {
      errors.push(`行 ${i + 1}: 必須項目が不足しています。`);
      // エラーがあっても処理を続行
      continue;
    }

    // 定数値との比較バリデーション
    const validations = [
      {
        fieldName: '基板サイズ',
        fieldValue: boardSize,
        validValues: validValues.boardSize,
      },
      {
        fieldName: '面方位',
        fieldValue: row[3] ? row[3].toString().trim() : '',
        validValues: validValues.orientation,
      },
      {
        fieldName: 'メーカー',
        fieldValue: manufacturer,
        validValues: validValues.manufacturer,
      },
      {
        fieldName: '精密研磨状況',
        fieldValue: row[9] ? row[9].toString().trim() : '',
        validValues: validValues.precisionPolishStatus,
        optional: true
      },
      {
        fieldName: '研磨依頼先',
        fieldValue: row[10] ? row[10].toString().trim() : '',
        validValues: validValues.polishingRequest,
        optional: true
      },
      {
        fieldName: 'プロジェクト',
        fieldValue: row[13] ? row[13].toString().trim() : '',
        validValues: validValues.project,
      },
      {
        fieldName: '所在地',
        fieldValue: row[14] ? row[14].toString().trim() : '',
        validValues: validValues.location,
        optional: true
      }
    ];

    let hasValidationError = false;

    for (const validation of validations) {
      const { fieldName, fieldValue, validValues: validOptionValues, optional = false } = validation;

      // 値が入力されている場合のみチェック（optional = trueの場合）
      if (fieldValue && !validOptionValues.includes(fieldValue)) {
        errors.push(
          `行 ${i + 1}: ${fieldName}に無効な値「${fieldValue}」が入力されています。\n` +
          `有効な値: ${validOptionValues.join(', ')}`
        );
        hasValidationError = true;
      }
      // 必須項目（optional = false）で値が空の場合
      else if (!optional && !fieldValue) {
        errors.push(`行 ${i + 1}: ${fieldName}は必須項目です。`);
        hasValidationError = true;
      }
    }

    if (hasValidationError) {
      continue;
    }

    // データの追加
    substrates.push(
      new RegisterSubstrate({
        size: boardSize,
        substrate_thickness: row[2] ? row[2].toString().trim() : '',
        plane_orientation: row[3] ? row[3].toString().trim() : '',
        cut_direction: row[4] ? row[4].toString().trim() : '',
        manufacturer: manufacturer,
        agency: row[6] ? row[6].toString().trim() : '',
        product_name: row[7] ? row[7].toString().trim() : '',
        purchase_budget: row[8] ? row[8].toString().trim() : '',
        precision_polish_status: row[9] ? row[9].toString().trim() : '',
        polish_request_destination: row[10] ? row[10].toString().trim() : '',
        polish_condition: row[11] ? row[11].toString().trim() : '',
        firstly_remarks: row[12] ? row[12].toString().trim() : '',
        project: row[13] ? row[13].toString().trim() : '',
        location: row[14] ? row[14].toString().trim() : '',
        usage: row[15] ? row[15].toString().trim() : '',
        remarks: row[16] ? row[16].toString().trim() : '',
      })
    );
  }

  return { substrates, errors };
};