import { ArrowBack, Save } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PutEditL2TypeMastar } from '../../api/PutEditL2TypeMastar';
import AppHeader from '../../components/AppHeader';
import CompletionModal from '../../components/CompletionModal';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_L2_TYPE_MASTER_LIST } from '../../consts/const';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)({
  backgroundColor: COLORS.SECONDARY,
  color: COLORS.BLACK,
  '&:hover': {
    backgroundColor: COLORS.TERTIARY,
  },
});

const EditL2TypeMastarScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: masterItem } = location.state || {};
  const [masterItemName, setMasterItemName] = useState(masterItem?.process_l2_type_name || '');
  const [isCompletionModalOpen, setCompletionModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [operation, setOperation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);


  // 登録ボタンのハンドラー
  const handleRegister = async () => {
    if (!masterItemName.trim()) {
      return; // 空の入力は処理しない
    }

    if (isSubmitting) {
      return; // 二重送信防止
    }

    setIsSubmitting(true);

    try {
      const requestData = {
        process_l2_type_name: masterItemName.trim()
      };

      console.log(masterItem?.process_l2_type_id)

      await PutEditL2TypeMastar(requestData, masterItem?.process_l2_type_id);

      setIsSuccess(true);
      setOperation('登録');
      setCompletionModalOpen(true);
    } catch (error) {
      console.error('登録エラー:', error);
      setIsSuccess(false);
      setOperation('登録');
      setCompletionModalOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleBackToList = () => {
    navigate(PATH_L2_TYPE_MASTER_LIST);
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              L2種別マスタ一覧
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                onClick={handleRegister}
                sx={{ color: 'white' }}
                disabled={isSubmitting || !masterItemName.trim()}
              >
                <SaveIcon />
              </IconButton>
              <CompletionModal
                open={isCompletionModalOpen}
                onClose={() => setCompletionModalOpen(false)}
                isSuccess={isSuccess}
                itemName={'プロセスL2種別名'}
                onBackToList={handleBackToList}
                operation={operation}
              />
            </Box>
          </HeaderPaper>
          <Box sx={{ p: 2, flexShrink: 0 }}>
            <Typography variant="subtitle1" gutterBottom>
              基本項目
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <TextField
                value={masterItemName}
                placeholder="プロセスL2種別名を入力"
                label='プロセスL2種別名'
                variant="outlined"
                fullWidth
                onChange={(e) => setMasterItemName(e.target.value)}
                disabled={isSubmitting}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, gap: 2, flexShrink: 0 }}>
              <Button
                sx={{ backgroundColor: COLORS.PRIMARY }}
                variant="contained"
                startIcon={<Save />}
                onClick={handleRegister}
                disabled={isSubmitting || !masterItemName.trim()}
              >
                登録
              </Button>
              <StyledButton
                sx={{ backgroundColor: 'white', color: 'black' }}
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                取り消し
              </StyledButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, backgroundColor: COLORS.SECONDARY, p: 2 }} />
        </MainContent>
      </Box>
    </Box>
  );
};

export default EditL2TypeMastarScreen;