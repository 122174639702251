import {
    Box,
    Paper,
    Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { GetEquipmentList } from '../api/GetEquipmentList';
import { Loading } from '../commons/Loading';
import AppHeader from '../components/AppHeader';
import DisPlayMasterListTable from '../components/DisplayMasterListTable';
import Sidebar from '../components/Sidebar';
import { COLORS } from '../constants/colors';

const MainContent = styled(Box)({
    backgroundColor: COLORS.BACKGROUND_GRAY,
    flexDirection: 'column',
    height: 'calc(100vh - 65px)',
    width: 'calc(100vw - 240px)',
    overflow: 'hidden',
    display: 'flex',
});

const HeaderPaper = styled(Paper)({
    backgroundColor: COLORS.PRIMARY,
    padding: '8px 16px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
});

const columns = [
    { header: '装置種別名', field: 'equipment_type_name' },
    { header: '装置名', field: 'equipment_name' },
    { header: 'メーカー', field: 'equipment_manufacturer' },
    { header: '所在地', field: 'equipment_location' },
];

const EquipmentListScreen = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {
        fetchEquipmentTypeMastar();
    }, []);

    const fetchEquipmentTypeMastar = async () => {
        setIsLoading(true);
        try {
            const data = await GetEquipmentList();
            if (!data) {
                throw new Error('データの取得に失敗しました');
            }

            // レスポンスデータをそのまま使用
            setDisplayData(data);
        } catch (error) {
            console.error('Failed to fetch L2 type master:', error);
            alert('測定装置の取得に失敗しました: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', overflow: 'hidden' }}>
            <Sidebar />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <AppHeader />
                <MainContent>
                    <HeaderPaper>
                        <Typography variant="h6" sx={{ color: 'white' }}>
                            測定装置一覧
                        </Typography>
                    </HeaderPaper>
                    <DisPlayMasterListTable
                        columns={columns}
                        data={displayData}
                        emptyMessage="測定装置がありません"
                    />
                </MainContent>
            </Box>
            <Loading isLoading={isLoading} />
        </Box>
    );
};

export default EquipmentListScreen;