// @ts-check
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../../components/AppHeader';
import ProcessListTable from '../../components/ProcessListTable';
import ProcessReusableSearch from '../../components/ProcessReusableSearch';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_CREATE_L2_PROCESS_MASTER } from '../../consts/const';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

// カラム定義を修正
const columns = [
  { header: 'L2プロセス', field: 'l2Process' },
  { header: 'タイトル', field: 'title' },
  { header: 'コメント', field: 'comment' },
  { header: '作成者', field: 'creater' },
];

  // ダミーデータ
  const processes = [
    { value: 'process1', label: 'プロセス1' },
    { value: 'process2', label: 'プロセス2' },
    { value: 'process3', label: 'プロセス3' },
  ];

  const creators = [
    { value: 'creator1', label: '作成者1' },
    { value: 'creator2', label: '作成者2' },
    { value: 'creator3', label: '作成者3' },
  ];


const ProcessListScreen = () => {
  const navigate = useNavigate()
  
  const handleSearch = (searchTerm) => {
    console.log('Searching for:', searchTerm);
    // 検索処理を実装
  };

  const handleFilter = (filterParams) => {
    console.log('Applying filters:', filterParams);
    // フィルタリング処理を実装
  };

  const handleCopy = (row) => {
    // コピー処理を実装
    console.log('Copy', row);
  };

  const handleNew = () => {
    navigate(PATH_CREATE_L2_PROCESS_MASTER, { state: { mode: 'create' } });
  };

  // ダミーデータを修正
  const dummyData = [
    {
      l2Process: '保護膜無し\nメッキなし\n通常プロセス',
      title: 'カレーが好き\n激辛の\n最新の流行は無水カレー',
      comment: 'コメントしか許可されていない場所です。',
      creater:'カレー大臣'
    },
    {
      l2Process: '保護膜無し\nメッキなし\n通常プロセス',
      title: 'カレーが好き\n激辛の\n最新の流行は無水カレー',
      sequence: '準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。',
      creater:'すき焼き大臣'
    },
    {
      l2Process: '保護膜無し\nメッキなし\n通常プロセス',
      title: 'カレーが好き\n激辛の\n最新の流行は無水カレー',
      sequence: '準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。',
      creater:'すき焼き大臣'
    },
    {
      l2Process: '保護膜無し\nメッキなし\n通常プロセス',
      title: 'カレーが好き\n激辛の\n最新の流行は無水カレー',
      sequence: '準備リンス1\nあれリンス2\nこれリンス3\n準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。',
      creater:'すき焼き大臣'
    },
    {
      l2Process: '保護膜無し\nメッキなし\n通常プロセス',
      title: 'カレーが好き\n激辛の\n最新の流行は無水カレー',
      sequence: '準備リンス1\nあれリンス2\nこれリンス3\n準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。',
      creater:'すき焼き大臣'
    },
    {
      l2Process: '保護膜無し\nメッキなし\n通常プロセス',
      title: 'カレーが好き\n激辛の\n最新の流行は無水カレー',
      sequence: '準備リンス1\nあれリンス2\nこれリンス3\n準備6\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理\nP2処理',
      comment: 'コメントしか許可されていない場所です。',
      creater:'すき焼き大臣'
    }
  ];

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              プロセス一覧
            </Typography>
            <IconButton onClick={handleNew} sx={{ color: 'white' }}>
              <AddIcon />
            </IconButton>
          </HeaderPaper>
          <ProcessReusableSearch
            label="タイトル"
            placeholder="タイトルを入力"
            processes={processes}
            creators={creators}
            onSearch={handleSearch}
            onFilter={handleFilter}
          />
          <ProcessListTable 
            columns={columns} 
            data={dummyData} 
            onCopy={handleCopy}
            onNew={handleNew}
          />
        </MainContent>
      </Box>
    </Box>
  );
};

export default ProcessListScreen;