// src/functions/signUp.js
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

import { BELONG_TO_OPTIONS, PATH_USER_LIST } from '../consts/const';
import {
  TEXT_ALERT_SIGN_UP_FAILED,
  TEXT_ALERT_SIGN_UP_SUCCESS,
} from '../consts/constText';
import { customConsole } from '../utils/customConsole';

export async function signUp(formValues) {
  const email = formValues.email;
  const name_first = formValues.name_first;
  const name_last = formValues.name_last;
  const kana_first = formValues.kana_first;
  const kana_last = formValues.kana_last;
  const belongTo = formValues.belongTo;
  const password = formValues.password;

  // 必須項目が入力されていない場合は処理を中断
  if (
      !email ||
      !name_first ||
      !name_last ||
      !kana_first ||
      !kana_last ||
      !belongTo ||
      !password
  ) {
    return;
  }

  // BELONG_TO_OPTIONSの要素を取得
  const belongToValues = BELONG_TO_OPTIONS.find(
      (option) => option.name === belongTo
  );

  // 必要なユーザー情報をセットアップ
  const userData = {
    email: email,
    password: password,
    name_first: name_first,
    name_last: name_last,
    kana_first: kana_first,
    kana_last: kana_last,
    belongTo: belongTo,
    user_belong_to_id: belongToValues.id,
    user_belong_to_label: belongToValues.label,
    user_belong_to_name: belongToValues.name,
    user_at_created: new Date().toISOString(),
    user_at_updated: new Date().toISOString(),
    user_at_last_login: new Date().toISOString(),
  };

  // TODO ローディング画面を出す
  try {
    // ユーザー登録処理
    const createUserFunction = httpsCallable(functions, 'createUser');
    const resultOfCreateUser = await createUserFunction(userData);
    const isResultOfCreateUser = resultOfCreateUser.data.isResult;

    if (!isResultOfCreateUser) {
      throw new Error('ユーザー作成に失敗しました。');
    } else {
      customConsole(() => {
        console.log(`Log: ユーザー作成に成功しました。`);
      });
    }

    const uid = resultOfCreateUser.data.uid;

    // GENERAL_USERロールを割り当て
    const setGeneralUserRoleFunction = httpsCallable(
        functions,
        'setGeneralUserRole'
    );

    const resultOfSetGeneralUserRole = await setGeneralUserRoleFunction({
      uid: uid,
    });
    const isResultOfSetGeneralUserRole =
        resultOfSetGeneralUserRole.data.isResult;

    if (!isResultOfSetGeneralUserRole) {
      throw new Error('ユーザーにGENERAL_USERロールの割り当てに失敗しました。');
    } else {
      customConsole(() => {
        console.log(
            `Log: ユーザーにGENERAL_USERロールの割り当てに成功しました。`
        );
      });
    }

    return true;
  } catch (error) {
    // 登録失敗
    const errorCode = error.code;
    const errorMessage = error.message;

    // エラーコードに対応するメッセージを設定
    let displayMessage = '';
    switch (errorCode) {
      case 'auth/email-already-in-use':
        displayMessage = 'このメールアドレスはすでに登録済みです。';
        break;
      default:
        displayMessage = errorMessage;
    }
    alert(displayMessage);

    return false;
  }
}
