// @ts-check

/**
 * 完全一致で検索を行う
 * @param {Array<import('../models/Substrate').Substrate>} data - 検索対象のSubstrate配列
 * @param {Object} conditions - 検索条件のオブジェクト
 * @returns {Array<import('../models/Substrate').Substrate>} - フィルタリングされたSubstrate配列
 */
export const exactMatch = (data, conditions) => {
  return data.filter(substrate => {
    return Object.entries(conditions).every(([key, value]) => {
      return value === '' || substrate[key] === value;
    });
  });
};

/**
 * 前方一致で検索を行う
 * @param {Array<import('../models/Substrate').Substrate>} data - 検索対象のSubstrate配列
 * @param {Object} conditions - 検索条件のオブジェクト
 * @returns {Array<import('../models/Substrate').Substrate>} - フィルタリングされたSubstrate配列
 */
export const startsWithMatch = (data, conditions) => {
  return data.filter(substrate => {
    return Object.entries(conditions).every(([key, value]) => {
      if (value === '') return true;
      const substrateValue = substrate[key];
      return substrateValue && substrateValue.toString().toLowerCase().startsWith(value.toLowerCase());
    });
  });
};

/**
 * 部分一致で検索を行う
 * @param {Array<import('../models/Substrate').Substrate>} data - 検索対象のSubstrate配列
 * @param {Object} conditions - 検索条件のオブジェクト
 * @returns {Array<import('../models/Substrate').Substrate>} - フィルタリングされたSubstrate配列
 */
export const partialMatch = (data, conditions) => {
  return data.filter(substrate => {
    return Object.entries(conditions).every(([key, value]) => {
      if (value === '') return true;
      const substrateValue = substrate[key];
      return substrateValue && substrateValue.toString().toLowerCase().includes(value.toLowerCase());
    });
  });
};

/**
 * SubstrateListSearch用の検索メソッド
 * @param {Array<import('../models/Substrate').Substrate>} data - 検索対象のSubstrate配列
 * @param {Object} conditions - 検索条件のオブジェクト
 * @returns {Array<import('../models/Substrate').Substrate>} - フィルタリングされたSubstrate配列
 */
export const substrateListSearch = (data, conditions) => {
  const { id, size, designNo, administrator, location } = conditions;

  // 全ての条件が空の場合、全てのデータを返す
  if (Object.values(conditions).every(value => value === '')) {
    return data;
  }

  // 完全一致の条件
  const exactConditions = {
    size,
    designNo,
    administrator,
    location
  };

  // 部分一致の条件
  const partialConditions = {
    id
  };

  // 完全一致で絞り込み
  let result = exactMatch(data, exactConditions);

  // 部分一致でさらに絞り込み
  result = partialMatch(result, partialConditions);

  return result;
};