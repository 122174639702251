import { Button, Grid, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../constants/colors';

const ReusableSearch = ({ label, placeholder, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch(searchTerm);
    }
  };

  return (
    <Paper sx={{ padding: 2, backgroundColor: COLORS.BACKGROUND_GRAY }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={10}>
          <TextField
            sx={{ backgroundColor: 'white' }}
            fullWidth
            label={label}
            placeholder={placeholder}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            sx={{ backgroundColor: COLORS.PRIMARY }}
            onClick={handleSearchClick}
          >
            検索
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReusableSearch;