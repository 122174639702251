// src/consts/const.js

export const ENV_LOCAL = 'LOCAL';
export const ENV_DEV = 'DEV';
export const ENV_STG = 'STG';
export const ENV_PRD = 'PRD';

export const ENV_TITLE_LOCAL = 'LOCAL';
export const ENV_TITLE_DEV = 'DEV';
export const ENV_TITLE_STG = 'STG';
export const ENV_TITLE_PRD = '';

// ユーザー権限
export const SUPER_USER = 'SUPER_USER';
export const ADMINISTRATOR = 'ADMINISTRATOR';
export const GENERAL_USER = 'GENERAL_USER';

export const TIME_ALERT_VIEW = 5000; // ms

// 所属リスト
export const BELONG_TO_OPTIONS = [
  { id: 0, label: 'ODD', name: '大熊ダイヤモンドデバイス' },
  // { id: 1, label: 'AISTつくば', name: 'AISTつくば' },
  // { id: 2, label: 'AIST関西', name: 'AIST関西' },
];

// ボタンのアニメーションが終了するまで待つ (例: 200ms)
export const MATERIAL_BUTTON_WAIT_TIME = 200;

// 画面の横幅がこの値以下の場合はスマホと判定する
export const SCREEN_WIDTH_SMARTPHONE = 768;

// APIのベースURl
export const API_BASE_URL = 'https://fastapi-cloud-run-348955843861.asia-northeast1.run.app/';

// パス
export const PATH_ROOT = '/TraceChainAmp';
export const PATH_OTHERS = PATH_ROOT + '/*';
export const PATH_BOARD_DETAILS = '/:id';
export const PATH_LOGIN = PATH_ROOT + '/login';
export const PATH_USER_LIST = PATH_ROOT + '/users';
export const PATH_SIGNUP = PATH_USER_LIST + '/signup';
export const PATH_TOP = PATH_ROOT + '/top';
export const PATH_REGISTER_SUBSTRATE = PATH_ROOT + '/register-substrate'
export const PATH_REGISTER_SUBSTRATE_SUCCESS = PATH_ROOT + '/register-substrate-success'
export const PATH_PROCESS_LIST = PATH_ROOT + '/process_list'
export const PATH_L2_PROCESS_MASTER_LIST = PATH_ROOT + '/l2_process_mastar_list'
export const PATH_CREATE_L2_PROCESS_MASTER = PATH_ROOT + '/create_l2_process_mastar'
export const PATH_L2_PROCESS_MASTER_DETAIL = PATH_ROOT + '/l2_process_mastar_detail'
export const PATH_L2_TYPE_MASTER_LIST = PATH_ROOT + '/l2_type_mastar_list'
export const PATH_MEASUREMENT_TYPE_MASTER_LIST = PATH_ROOT + '/measurement_type_mastar_list'
export const PATH_EQUIMENT_TYPE_MASTER_LIST = PATH_ROOT + '/equipment_type_mastar_list'
export const PATH_EQUIMENT_LIST = PATH_ROOT + '/equipment_list'
export const PATH_EDIT_L2_TYPE_MASTER = PATH_ROOT + '/edit_l2_type_mastar'
export const PATH_REGISTER_L2_TYPE_MASTER = PATH_ROOT + '/register_l2_type_mastar'
export const PATH_EDIT_MEASUREMENT_TYPE_MASTER = PATH_ROOT + '/edit_measurement_type_mastar'
export const PATH_REGISTER_MEASUREMENT_TYPE_MASTER = PATH_ROOT + '/register_measurement_type_mastar'
export const PATH_ACCOUNT = PATH_ROOT + '/account';
export const PATH_KIBAN_LIST = PATH_ROOT + '/kiban';
export const PATH_KIBAN_DETAIL = PATH_KIBAN_LIST + '/detail/:kibanId';
export const PATH_ROOT_OUT = '*';

// 選択可能なタグの最大表示数
export const MAX_DISPLAY_TAGS = 15;

// 画像の最大サイズ（5MB）
export const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
export const MAX_IMAGE_SIZE_TEXT = '5MB';

export const MAX_IMAGES_NUM_5 = 5;

export const TEXT_LIMIT_NUM_STANDARD_1000 = 1000;
export const TEXT_LIMIT_NUM_COMMON_50 = 50;
export const TEXT_LIMIT_NUM_COMMON_1000 = 1000;

export const _ = require('lodash');

export const TEMPLATE_USER_COMMON = {
  uid: null,
  user_name: {
    setting_value: null,
    public_type: 'public',
    add_detail: null,
  },
  user_icon: null,

  // ユーザー設定
  user_conf: {},

  // 登録日、更新日
  user_at_created: null,
  user_at_updated: null,
};

// ユーザー情報の初期値
export const INITIAL_USER_INFO = (user, formValues) => ({
  ...TEMPLATE_USER_COMMON,
  uid: `${user.uid}`,
  user_name: {
    setting_value: `${formValues.userName}`,
    public_type: 'public',
    add_detail: null,
  },

  user_info: {},

  // ユーザー設定
  // TODO_MVP: 必要に応じて追加
  user_conf: {},

  user_at_created: new Date().toISOString(),
  user_at_updated: new Date().toISOString(),
  user_at_last_login: new Date().toISOString(),
  // memo: 後でDate型に変換する場合は下記
  // let dateObj = new Date(yourISOString);
});
