// src/const/constCss.js
// 色
export const COLOR_BACKGROUND_DARK_WHITE = '#f5f5f5';
export const COLOR_BACKGROUND_WHITE = '#fff';

export const COLOR_SHADOW_GRAY = 'rgba(0, 0, 0, 0.16)';

export const COLOR_BORDER = '#e6ecf0';

export const COLOR_COMMON_GRAY = '#808080';
export const COLOR_COMMON_DARK_GRAY = '#333';
export const COLOR_COMMON_DARK_WHITE = '#f0f0f0';

export const COLOR_COMMON_RED = '#ff0000';

export const COLOR_COMMON_1 = '#44cbb1';

export const COLOR_BUTTON_A = COLOR_COMMON_1;
export const COLOR_BUTTON_GRAY = '#e6ecf0';
export const COLOR_BUTTON_FONT_BLACK = '#363636';
export const COLOR_BUTTON_FONT_WHITE = '#fff';

export const COLOR_TEXT_COMMON = '#000000';
export const COLOR_TEXT_COMMON_GRAY = '#808080';
export const COLOR_TEXT_COMMON_HOVERED = '#808080';

export const COLOR_MENU_SELECTED = COLOR_COMMON_1;

export const COLOR_MUI_DEFAULT = '#808080';
export const COLOR_MUI_FOCUSED = '#1976D2';
export const COLOR_MUI_VALIDATION = '#D3302F';

// フォント
export const FONT_FAMILY = '"Noto Sans JP", sans-serif';

// フォントサイズ

export const FONT_SIZE_LARGE_1 = '20px';

export const FONT_SIZE_HEADER = '15px';

export const FONT_SIZE_MEDIUM_1 = '14px';

export const FONT_SIZE_SMALL_1 = '12px';

export const FONT_SIZE_MENU = '11px';
export const FONT_SIZE_MENU_ICON = '16px';

export const FONT_SIZE_SETTING_TITLE = '14px';
export const FONT_SIZE_SETTING_IS_PRIVATE = '10px';
export const FONT_SIZE_SETTING_VALUE = '13px';
export const FONT_SIZE_SETTING_ADD_DETAIL = '10px';
