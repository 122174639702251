import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { COLORS } from '../constants/colors';

// スタイリング
const StyledTableContainer = styled(TableContainer)({
  overflowX: 'auto',
  maxHeight: '600px',
  overflowY: 'auto',
  marginRight: 10,
  marginLeft: 10,
});

const StyledTableCell = styled(TableCell)(({ component }) => ({
  borderBottom: `1px solid ${COLORS.SECONDARY}`,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  ...(component === 'th' && {
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.WHITE,
    position: 'sticky',
    top: 0,
  }),
}));

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5',
  },
});

const RegisterSubstrateTable = ({ columns, data }) => {
  return (
    <StyledTableContainer >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <StyledTableCell key={col.header} component="th">
                {col.header}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((rowData, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {columns.map((col, colIndex) => (
                <StyledTableCell key={colIndex}>
                  {col.render
                    ? col.render(rowData[col.field], rowData)
                    : rowData[col.field]}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

RegisterSubstrateTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RegisterSubstrateTable;
