// src/components/SettingComponentAccountIconChange.jsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Box, Grid } from '@mui/material';

import {
  COLOR_BUTTON_A,
  COLOR_BUTTON_GRAY,
  COLOR_BUTTON_FONT_BLACK,
} from '../consts/constCss';
import {
  TEXT_CANCEL,
  TEXT_EDIT,
  TEXT_SETTING_CHANGE_ICON,
  TEXT_SETTING_ALERT_MAX_IMAGE_SIZE_ERROR,
} from '../consts/constText';

import { updateFirebaseAndReduxUserInfo } from '../utils/updateFirebaseAndReduxUserInfo';
import { uploadImageToFirebaseStorage } from '../utils/uploadImageToFirebaseStorage';

import AccountIcon from './AccountIcon';
import { Loading } from '../commons/Loading';

import { deleteObject, getStorage, ref } from 'firebase/storage';

import { SettingComponentBaseMinimum } from './SettingComponentBaseMinimum';
import { selectUserInfo } from '../redux/userDataSlice';
import { MAX_IMAGE_SIZE } from '../consts/const';
import { getUserUid } from '../utils/getUserUid';

export const SettingComponentAccountIconChange = ({
  title,
  baseKey,
  isViewPrivateText,
  supplementText,
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // 画像アップロード中の状態を管理
  const [imageError, setImageError] = useState(''); // 画像エラーの状態を管理

  const userInfo = useSelector(selectUserInfo);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setImageError('');
  };

  const handleImageChange = async (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      // 画像のサイズが5MBを超えている場合はエラーを表示
      if (image.size > MAX_IMAGE_SIZE) {
        setImageError(TEXT_SETTING_ALERT_MAX_IMAGE_SIZE_ERROR);
        return;
      }

      setIsUploading(true);
      setImageError('');

      try {
        // 画像をFirebase Storageにアップロード
        const imageUrl = await uploadImageToFirebaseStorage(image, baseKey);

        const isAppropriate = true; // 画像が適切かどうかの判定、とりあえずtrueにしておく

        if (!isAppropriate) {
          setImageError('画像が不適切な内容を含みます');

          const uid = getUserUid();

          // 不適切な画像をFirebase Storageから削除
          const storage = getStorage();
          const imageRef = ref(storage, `users/${uid}/${baseKey}`);
          await deleteObject(imageRef);

          setIsUploading(false);
          return;
        }

        // Redux と Firebase のユーザ情報を更新
        await updateFirebaseAndReduxUserInfo(dispatch, baseKey, imageUrl);
      } catch (error) {
        console.error(error);
      }

      setIsUploading(false);
      setIsEditing(false);
    }
  };

  return (
    <SettingComponentBaseMinimum
      title={title}
      isViewPrivateText={isViewPrivateText}
      supplementText={supplementText}
    >
      {/* ローディング */}
      <Loading isLoading={isUploading} />

      {/* 画像エラーメッセージ */}
      {imageError && (
        <div style={{ color: 'red', paddingLeft: '24px' }}>{imageError}</div>
      )}

      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginTop: '0px' }}
      >
        {/* フォーム */}
        <Grid
          item
          xs={12}
          sm={6}
          style={{ textAlign: 'left', paddingLeft: '24px' }}
        >
          <AccountIcon size={120} userInfo={userInfo} />
        </Grid>
        {/* ボタン */}

        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="flex-end" flexWrap="nowrap">
            {isEditing ? (
              <Box display="flex" justifyContent="flex-end" flexWrap="nowrap">
                {/* アイコン選択ボタン */}
                <input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="icon-button-file">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    component="span" // これがないと画像選択モーダルが表示されない
                    disabled={isUploading}
                    style={{
                      marginRight: '10px',
                      color: COLOR_BUTTON_A,
                      borderColor: COLOR_BUTTON_A,
                      minWidth: '110px',
                    }}
                  >
                    {TEXT_SETTING_CHANGE_ICON}
                  </Button>
                </label>

                {/* キャンセルボタン */}
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  size="small"
                  style={{
                    color: COLOR_BUTTON_FONT_BLACK,
                    borderColor: COLOR_BUTTON_GRAY,
                    minWidth: '80px',
                  }}
                >
                  {TEXT_CANCEL}
                </Button>
              </Box>
            ) : (
              //   編集ボタン
              <Button
                variant="outlined"
                size="small"
                onClick={handleEdit}
                style={{
                  color: COLOR_BUTTON_FONT_BLACK,
                  borderColor: COLOR_BUTTON_GRAY,
                }}
              >
                {TEXT_EDIT}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </SettingComponentBaseMinimum>
  );
};
