export class Substrate {
  constructor(data) {
    this.no = null;
    this.id = data.substrate_id;
    this.designNo = data.design_no;
    this.status = data.status || 'N/A';
    this.size = data.size
    this.updatedAt = new Date(data.updated_at);
    this.updatedBy = data.updated_by;
    this.location = data.location;
    this.project = data.project;
    this.usage = data.usage;
    this.administrator = data.administrator;
    this.remarks = data.remarks;
    this.thickness = data.substrate_thickness;
    this.planeOrientation = data.plane_orientation;
    this.cutDirection = data.cut_direction;
    this.manufacturer = data.manufacturer;
    this.productName = data.product_name;
    this.purchaseBudget = data.purchase_budget;
    this.firstlyRemarks = data.firstly_remarks;
    this.createdAt = new Date(data.created_at);
    this.precisionPolishStatus = data.precision_polish_status;
    this.polishRequestDestination = data.polish_request_destination;
    this.polishCondition = data.polish_condition;
  }

  get processingHistory() {
    return this.precisionPolishStatus ? '研磨済み' : '未研磨';
  }

  // 各タブに対応するデータを返すメソッド
  getStatusData() {
    return [this.no, this.id, this.designNo, this.status, this.updatedBy, this.location];
  }

  getUsageData() {
    return [this.no, this.id, this.project, this.usage, this.designNo, this.administrator, this.remarks];
  }

  getPurchaseData() {
    return [
      this.no,
      this.id,
      this.thickness,
      this.planeOrientation,
      this.cutDirection,
      this.manufacturer,
      this.productName,
      this.purchaseBudget,
      this.firstlyRemarks,
      this.createdAt.toLocaleDateString(),
      this.updatedAt.toLocaleDateString()
    ];
  }

  getProcessingData() {
    return [this.no, this.id, this.processingHistory, this.polishRequestDestination, this.polishCondition];
  }
}