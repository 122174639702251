import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchLocations, fetchProjects } from '../../functions/fetchFireStore';

const EditSection = ({ selectedRows, data, onBulkUpdate, onClose, adminUserNames }) => {
  const [editData, setEditData] = useState({
    project: '',
    location: '',
    administrator: '',
    remarks: ''
  });

  const [locations, setLocations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [fetchedLocations, fetchedProjects] = await Promise.all([
          fetchLocations(),
          fetchProjects()
        ]);
        setLocations(fetchedLocations);
        setProjects(fetchedProjects);
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (selectedRows.length === 1) {
      const selectedRow = data.find(row => row.id === selectedRows[0]);
      if (selectedRow) {
        setEditData({
          project: selectedRow.project || '',
          location: selectedRow.location || '',
          administrator: selectedRow.administrator || '',
          remarks: selectedRow.remarks || ''
        });
      }
    } else {
      setEditData({
        project: '',
        location: '',
        administrator: '',
        remarks: ''
      });
    }
  }, [selectedRows, data]);

  const handleChange = (field) => (event) => {
    setEditData(prevState => ({
      ...prevState,
      [field]: event.target.value
    }));
  };

  const handleSubmit = () => {
    onBulkUpdate(editData);
  };

  if (loading) {
    return <Box sx={{ p: 2 }}>Loading...</Box>;
  }

  return (
    <Box sx={{ mt: 2, p: 2, bgcolor: '#f5f5f5' }}>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        入力した項目のみ更新されます。未入力の項目は更新されません。
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="プロジェクト"
            value={editData.project}
            onChange={handleChange('project')}
          >
            <MenuItem value="">
              <em>選択なし</em>
            </MenuItem>
            {projects.map((project) => (
              <MenuItem key={project} value={project}>
                {project}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="所在地"
            value={editData.location}
            onChange={handleChange('location')}
          >
            <MenuItem value="">
              <em>選択なし</em>
            </MenuItem>
            {locations.map((location) => (
              <MenuItem key={location} value={location}>
                {location}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="管理者"
            value={editData.administrator}
            onChange={handleChange('administrator')}
          >
            <MenuItem value="">
              <em>選択なし</em>
            </MenuItem>
            {adminUserNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="備考"
            value={editData.remarks}
            onChange={handleChange('remarks')}
            multiline
            rows={2}
            placeholder="備考を入力"
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ mr: 1 }}
          disabled={selectedRows.length === 0}
        >
          一括更新
        </Button>
        <Button variant="outlined" onClick={onClose}>
          閉じる
        </Button>
      </Box>
    </Box>
  );
};

export default EditSection;