// src/utils/getUserPermission.js
import { customConsole } from './customConsole';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../redux/userDataSlice';

export const getUserPermission = () => {
  const userInfo = useSelector(selectUserInfo);

  return userInfo.permission;
};
