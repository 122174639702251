import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { COLORS } from '../constants/colors';

const logo = '/images/HeaderLogo.png';

const StyledAppBar = styled(AppBar)({
  backgroundColor: COLORS.SECONDARY,
  position: 'fixed',
});

const Logo = styled('img')({
  height: 46,
});

const AppHeader = () => {
  return (
    <StyledAppBar>
      <Toolbar >
        <Logo src={logo} alt="Logo" />
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppHeader;