// src/utils/updateFirebaseUserAtLastLogin.js
import { updateDoc } from 'firebase/firestore';

import { _ } from '../consts/const';

import { IS_DEBUG } from '../consts/constDebug';
import { customConsole } from './customConsole';

// 最終ログイン日時を更新
export const updateFirebaseUserAtLastLogin = async (docRef, docSnapData) => {
  try {
    let newUserInfo = docSnapData;

    // 更新日を追加
    _.set(newUserInfo, 'user_at_last_login', new Date().toISOString());

    await updateDoc(docRef, newUserInfo);

    customConsole(() => {
      console.log('Log: ユーザーデータ更新完了: user_at_last_login');
    });
  } catch (error) {
    console.error('ユーザーデータの更新中にエラーが発生しました:', error);
  }
};
