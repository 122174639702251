import { API_BASE_URL } from '../consts/const';
import { auth } from '../firebase';

const END_URL = 'process_l2_types/';

export const PutEditL2TypeMastar = async (requestData, masterItemId) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            throw new Error('User is not authenticated');
        }
        const idToken = await user.getIdToken();

        const response = await fetch(`${API_BASE_URL}${END_URL}${masterItemId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify(requestData),
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `HTTPエラー: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('API呼び出しエラー:', error);
        throw error;
    }
};