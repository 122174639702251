import { ArrowBack, Save } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PutMeasureTypes } from '../../api/PutMeasureTypes';
import { Loading } from '../../commons/Loading';
import AppHeader from '../../components/AppHeader';
import CompletionModal from '../../components/CompletionModal';
import Sidebar from '../../components/Sidebar';
import { COLORS } from '../../constants/colors';
import { PATH_MEASUREMENT_TYPE_MASTER_LIST } from '../../consts/const';

const MainContent = styled(Box)({
  backgroundColor: COLORS.BACKGROUND_GRAY,
  flexDirection: 'column',
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 240px)',
  overflow: 'hidden',
  display: 'flex',
});

const HeaderPaper = styled(Paper)({
  backgroundColor: COLORS.PRIMARY,
  padding: '8px 16px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)({
  backgroundColor: COLORS.SECONDARY,
  color: COLORS.BLACK,
  '&:hover': {
    backgroundColor: COLORS.TERTIARY,
  },
});

const EditMeasurementTypeMastarScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: masterItem } = location.state || {};
  const [measurementTypeName, setMeasurementTypeName] = useState(masterItem.measure_type_name);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const id = masterItem.measure_type_id;
  const fileFormat = masterItem.file_format;
  const equipmentType = masterItem.equipment_type_name;

  // 登録ボタンが押されたときの処理
  const handleRegister = async () => {
    if (isSubmitting) return;
    if (!measurementTypeName.trim()) {
      alert('必須項目を入力してください');
      return;
    }

    setIsSubmitting(true);
    try {
      const requestData = {
        measure_type_name: measurementTypeName.trim(),
      };

      await PutMeasureTypes(requestData, id);
      setIsSuccess(true);
      setModalOpen(true);
    } catch (error) {
      console.error('Failed to register measurement type:', error);
      setIsSuccess(false);
      setModalOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  // 画面のリセット処理
  const handleCancel = () => {
    navigate(-1);
  };

  // モーダルを閉じる処理
  const handleCloseModal = () => {
    setModalOpen(false);
    if (isSuccess) {
      navigate(PATH_MEASUREMENT_TYPE_MASTER_LIST);
    }
  };

  // 一覧に戻る処理
  const handleBackToList = () => {
    navigate(PATH_MEASUREMENT_TYPE_MASTER_LIST);
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <AppHeader />
        <MainContent>
          <HeaderPaper>
            <Typography variant="h6" sx={{ color: 'white' }}>
              測定種別マスタ編集
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                onClick={handleRegister}
                sx={{ color: 'white' }}
                disabled={isSubmitting || !measurementTypeName.trim()}
              >
                <SaveIcon />
              </IconButton>
            </Box>
          </HeaderPaper>
          <Box sx={{ p: 2, flexShrink: 0 }}>
            <Typography variant="subtitle1" gutterBottom>
              基本項目
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              <TextField
                value={id}
                label='ID'
                variant="filled"
                InputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                value={measurementTypeName}
                placeholder="測定種別名を入力"
                label='測定種別名'
                variant="outlined"
                fullWidth
                onChange={(e) => setMeasurementTypeName(e.target.value)}
                disabled={isSubmitting}
              />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  value={equipmentType}
                  label='測定装置種別名'
                  variant="filled"
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
                <TextField
                  value={fileFormat}
                  label='ファイル形式'
                  variant="filled"
                  InputProps={{ readOnly: true }}
                  fullWidth
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, gap: 2, flexShrink: 0 }}>
              <Button
                sx={{ backgroundColor: COLORS.PRIMARY }}
                variant="contained"
                startIcon={<Save />}
                onClick={handleRegister}
                disabled={isSubmitting || !measurementTypeName.trim()}
              >
                登録
              </Button>
              <StyledButton
                sx={{ backgroundColor: 'white', color: 'black' }}
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                取り消し
              </StyledButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, backgroundColor: COLORS.SECONDARY, p: 2 }} />
        </MainContent>

        <CompletionModal
          open={modalOpen}
          onClose={handleCloseModal}
          isSuccess={isSuccess}
          itemName="測定種別"
          operation="登録"
          onBackToList={handleBackToList}
        />
      </Box>
      <Loading isLoading={isSubmitting} />
    </Box>
  );
};

export default EditMeasurementTypeMastarScreen;
