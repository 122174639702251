// src/components/AccountIcon.js
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../redux/userDataSlice';

import { Avatar } from '@mui/material';

const AccountIcon = ({ size = 32, userInfo }) => {
  // const userInfo = useSelector(selectUserInfo);

  if (!userInfo || !userInfo.user_name) {
    return null;
  }

  return (
    <>
      {/* userInfoが存在しない場合はデフォルト、存在しアイコンが設定されている場合は画像、設定のない場合は文字1文字 */}
      {userInfo.user_icon ? (
        <Avatar
          variant="square"
          src={userInfo.user_icon}
          sx={{ width: size, height: size, borderRadius: '20%' }}
        />
      ) : (
        <Avatar
          variant="square"
          sx={{
            width: size,
            height: size,
            borderRadius: '20%',
            fontSize: size * 0.7,
          }}
        >
          {userInfo.user_name.setting_value.slice(0, 1)}
        </Avatar>
      )}
    </>
  );
};

//  アイコンを丸にする場合
//     <Avatar src={logo} sx={{ width: 32, height: 32 }} />

export default AccountIcon;
