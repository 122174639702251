// src/utils/getFirebaseChipsFormattedList.js

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

import { IS_DEBUG } from '../consts/constDebug';
import { _ } from '../consts/const';
import { customConsole } from './customConsole';

export const getFirebaseChipsFormattedList = async () => {
  try {
    const docRef = doc(db, 'settings', 'chips');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const fetchedData = docSnap.data();
      const sortedData = Object.entries(fetchedData).reduce(
        (acc, [key, value]) => {
          const sortedSubData = Object.entries(value)
            .sort((a, b) => b[1].sumCount - a[1].sumCount)
            .map(([subKey, subValue]) => ({ key: subKey, value: subValue }));
          acc[key] = [...sortedSubData];
          return acc;
        },
        {}
      );

      customConsole(() => {
        console.log(`Log: チップの取得完了`);
      });

      return sortedData;
    }
  } catch (error) {
    console.error('チップの取得中にエラーが発生しました:', error);
  }
};
