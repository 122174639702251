import { signOut } from 'firebase/auth';

import { setIsLogin } from '../redux/userDataSlice';
import { PATH_ROOT } from '../consts/const';
import { auth } from '../firebase';

export function logout(dispatch, navigate) {
  // setInitialChipsOnFirebase();

  signOut(auth)
    .then(() => {
      // ログアウト成功
      // ログイン状態を更新
      dispatch(setIsLogin(false));
      // 初期へリダイレクト
      navigate(PATH_ROOT);
    })
    .catch((error) => {
      // ログアウト失敗
      const errorMessage = error.message;
      // エラーメッセージを表示
      alert(errorMessage);
    });
}
