import React, { Fragment, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Box,
  Avatar,
  Menu,
  ListItemIcon,
  Divider,
  IconButton,
  ListItemButton,
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../functions/logout';
import {
  PATH_ACCOUNT,
} from '../consts/const';

import {
  COLOR_MENU_SELECTED,
  COLOR_SHADOW_GRAY,
  FONT_SIZE_MENU,
  FONT_SIZE_MENU_ICON,
} from '../consts/constCss';
import {
  TEXT_CREATE_RECRUIT,
  TEXT_LOGOUT,
  TEXT_MENU_ACCOUNT,
  TEXT_MENU_NEWS,
  TEXT_MENU_RECRUIT_MANAGEMENT,
} from '../consts/constText';

import AccountIcon from './AccountIcon';
import { selectUserInfo } from '../redux/userDataSlice';

// TODO_いつか: メニューからマウスを外したらメニューが消えるようにする（なぜかうまくいかない）

export default function AccountMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //   const open = true; memo: DEBUGでメニューを常に表示する場合はこちらを使う

  const userInfo = useSelector(selectUserInfo);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          p: '0 16px 0 8px',
          flexGrow: 1,
        }}
        onMouseEnter={handleOpen} // Boxへマウスがホバーした際にメニューを表示
        onMouseLeave={handleClose} // Boxからマウスが離れた際にメニューを非表示
      >
        <IconButton
          size="small"
          sx={{ m: 0 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AccountIcon userInfo={userInfo} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onMouseLeave={handleClose} // Boxからマウスが離れた際にメニューを非表示
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: `drop-shadow(0px 1px 4px ${COLOR_SHADOW_GRAY})`,
              mt: 4, // メニューの高さ調整
              marginLeft: -0.5, // メニューを左に移動

              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <ListItemButton
            component={Link}
            to={PATH_ACCOUNT}
            sx={{
              fontSize: FONT_SIZE_MENU,
              color:
                location.pathname === PATH_ACCOUNT
                  ? COLOR_MENU_SELECTED
                  : 'inherit',
            }}
            onClick={handleClose}
          >
            {TEXT_MENU_ACCOUNT}
          </ListItemButton>

          <Divider sx={{ mt: '4px', mb: '4px' }} />

          <ListItemButton
            sx={{ fontSize: FONT_SIZE_MENU }}
            onClick={() => {
              logout(dispatch, navigate);
              handleClose();
            }}
          >
            <ListItemIcon sx={{ paddingRight: 0, mr: '-36px' }}>
              <LogoutIcon sx={{ fontSize: FONT_SIZE_MENU_ICON }} />
            </ListItemIcon>
            {TEXT_LOGOUT}
          </ListItemButton>
        </Menu>
      </Box>
    </Fragment>
  );
}
